import React, { useState, useEffect, useMemo, memo, useCallback } from 'react';
import {
  Box, Typography, Stack, ThemeProvider, TextField, Button,
  Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tab,Link, Tooltip,
  Switch, FormControlLabel, Select, MenuItem
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Edit, Upload, ThumbUp, ThumbDown, Sort as SortIcon } from '@mui/icons-material';
import { theme } from '../Theme';
import { isAdmin, isESManager, isEDManager } from '../msalService';
import { getDecisionHubData, updateAprBulk, getEsDecisionHubData, approveAprBulk, orderDomainsBulk, zeroAndArchiveBulk, sendBackBulk, fetchStats} from '../api';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import CopyrightIcon from '@mui/icons-material/Copyright';
import MultiSortDialog from '../MultiSortDialog';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import UpdateShortlistedDomain from '../Prospector/UpdateShortlistedDomain';

function DecisionHub() {
  const [data, setData] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [updatedAprDomains, setUpdatedAprDomains] = useState({});
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewAction, setPreviewAction] = useState('');
  const [mode, setMode] = useState('');
  const [rowSelection, setRowSelection] = useState({});
  const [tabValue, setTabValue] = useState('ALL');
  const [multiSortDialogOpen, setMultiSortDialogOpen] = useState(false);
  const [isZeroReco, setIsZeroReco] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [isNlDomain, setIsNlDomain] = useState(false);
  const [localAprValues, setLocalAprValues] = useState({});
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isEDManagerUser = isEDManager();
  const currentYear = new Date().getFullYear();


  const listTypes = mode === 'ed'
    ? ['ALL','T0', 'T1', 'T-BA', 'T-PD', 'T-PN', 'T-PC']
    : ['ALL','T0', 'T1', 'T-PD', 'T-PN', 'T-PC', 'T-RP'];

    const getSnackbarMessage = (action) => {
      switch(action) {
        case 'Approval': return 'Domains approved!';
        case 'Order': return 'Domains ordered!';
        case 'ZeroArchive': return 'Domains zero archived!';
        case 'SendBack': return 'Domains sent back!';
        default: return `Domains ${action}d successfully`;
      }
    };

  useEffect(() => {
    let initialMode = 'ed';
    if (isAdminUser||isEDManagerUser) {
      initialMode = 'ed';
    } else if (isESManagerUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
  }, [isAdminUser, isESManagerUser, isEDManagerUser]);

  const fetchDecisionHubData = useCallback((currentMode, tab) => {
    setLoading(true);
    const apiCall = currentMode === 'ed' ? getDecisionHubData : getEsDecisionHubData;
    apiCall({ tab, isZeroReco, isNlDomain }).then(res => {
      if (Array.isArray(res.data)) {
        const transformedData = res.data.map(item => ({
          acquShortlistedDomain: {
            id: item.id,
            domain: item.domain,
            list: item.list,
            host: item.host,
            tld: item.tld,
            wby: item.wby,
            aby: item.aby,
            reg: item.reg,
            regx: item.regx,
            sg: item.sg,
            cpc: item.cpc,
            bids: item.bids,
            price: item.price,
            est: item.est,
            gdv: item.gdv,
            reco: item.reco,
            apr: item.apr,
            edate: item.edate,
            createdAt: item.createdAt,
            tld_count: item.tld_count,
            tld_count_dev: item.tld_count_dev,
            p: item.p,
            len: item.len,
            esw: item.esw,
            gdw: item.gdw,
            aprUnaprBy: item.aprUnaprBy,
            recoBy: item.recoBy,
            spr500: item.spr500,
            sprGdv: item.sprGdv,
            remarks: item.remarks,
            recoRemark: item.recoRemark,
            aprRemark: item.aprRemark
          },
          socialLeads: item.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
            const leadData = lead.correctedLead || lead;
            
            return {
              id: leadData.id,
              domain: leadData.domain,
              domainSnapShotUrl: leadData.domainSnapShotUrl,
              copyright: leadData.copyright,
              description: leadData.description,
              isIrrelevant: leadData.irrelevant ?? false,
              missedStatus: leadData.missedStatus ?? 0,
              code: leadData.code,
              noSocialsAvailable: leadData.noSocialsAvailable ?? false,
              isInfluencer: leadData.isInfluencer ?? false,
              leadStatus: leadData.leadStatus ?? "active",
              wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
              socials: lead.socials?.map(social => {
                const socialData = social.correctedSocial || social;
                
                return {
                  id: socialData.id,
                  platform: socialData.platform.toLowerCase(),
                  status: socialData.status,
                  followers: socialData.followers,
                  employees: socialData.employees,
                  url: socialData.url,
                  userFullName: socialData.userFullName,
                  isIrrelevant: socialData.irrelevant ?? false,
                  missedStatus: socialData.missedStatus ?? 0,
                  wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                };
              }) || [],
              email: lead.emailWorks?.[0]?.emails?.[0] ? {
                id: lead.emailWorks[0].emails[0].id,
                email: lead.emailWorks[0].emails[0].email,
                name: lead.emailWorks[0].emails[0].name,
                designation: lead.emailWorks[0].emails[0].designation,
                linkedinUrl: lead.emailWorks[0].emails[0].linkedinUrl
              } : null
            };
          }) || [],
          socialWorkId: item.socialDomain?.socialWorks?.[0]?.id || null,
          reviewRemark: item.socialDomain?.socialWorks?.[0]?.reviewRemark ,
          noLeadsAvailable: item.socialDomain?.socialWorks?.[0]?.noLeadsAvailable ,
          agents: {
            uploader: currentMode === 'ed' 
              ? (item.user ? {
                  name: `${item.user.firstName} ${item.user.lastName}`,
                  email: item.user.email,
                  role: 'Uploader'
                } : null)
              : (item.aqShortlistings?.length > 0 
                  ? item.aqShortlistings.map(shortlisting => ({
                      name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                      email: shortlisting.user.email,
                      role: 'Shortlister'
                    }))
                  : []),
            processor: item.socialDomain?.socialWorks?.[0]?.user ? {
              name: `${item.socialDomain.socialWorks[0].user.firstName} ${item.socialDomain.socialWorks[0].user.lastName}`,
              email: item.socialDomain.socialWorks[0].user.email,
              role: 'Processor'
            } : null,
            reviewer: item.socialDomain?.presentAllocation?.reviewer ? {
              name: `${item.socialDomain.presentAllocation?.reviewer.firstName} ${item.socialDomain.presentAllocation?.reviewer.lastName}`,
              email: item.socialDomain.presentAllocation?.reviewer.email,
              role: 'Reviewer',
              reviewStatus: item.socialDomain.presentAllocation.reviewStatus,
              reviewRemark: item.socialDomain.presentAllocation.reviewRemark,
              noLeadsAvailable: item.socialDomain.presentAllocation.noLeadsAvailable,
            } : null,
            recommender: item.recoBy ? {
              name: typeof item.recoBy === 'string' 
                ? item.recoBy 
                : `${item.recoBy.firstName} ${item.recoBy.lastName}`,
              email: item.recoBy.email,
              role: 'Recommender'
            } : null,
            approver: item.aprUnaprBy ? {
              name: item.aprUnaprBy,
              role: 'Approver'
            } : null
          }
        }));
        setData(transformedData);
      } else {
        console.error('API response is not an array:', res);
        setData([]);
      }
    }).catch(error => {
      console.error('Error fetching data:', error);
      setData([]);
    }).finally(() => {
      setLoading(false);
    });
  }, [isZeroReco, isNlDomain]);

  useEffect(() => {
    if (mode !== '') {
      fetchDecisionHubData(mode, tabValue);
    }
  }, [isZeroReco, isNlDomain, mode, tabValue]);


  const handleAprChange = useCallback((id, value) => {
    const numValue = Number(value);
    if (!isNaN(numValue)) {
      setUpdatedAprDomains(prev => ({
        ...prev,
        [id]: numValue
      }));
    }
  }, []);

  const handleAprRemarkChange = useCallback((id, value) => {
    setUpdatedAprDomains(prev => ({
      ...prev,
      aprRemark: {
        ...prev.aprRemark,
        [id]: value
      }
    }));
  }, []);

  const handleActionChange = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    setPreviewAction(action);
    setPreviewDialogOpen(true);
  };

  const handleFetchStats = () => {
    setLoading(true);
    // Create an object mapping domain indices to true for selected rows
    const selectedDomainIndices = Object.keys(rowSelection).reduce((acc, index) => {
      if (rowSelection[index]) {
        const domainId = data[index].acquShortlistedDomain.id;
        acc[domainId] = true;
      }
      return acc;
    }, {});

    fetchStats(selectedDomainIndices).then(res => {
      setSnackbar({ open: true, message: 'Stats fetched successfully', severity: 'success' });
      setRowSelection({});
      fetchDecisionHubData(mode, tabValue);
    }).catch(error => {
      setSnackbar({ open: true, message: 'Error fetching stats', severity: 'error' });
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleFinalSubmit = async () => {
    try {
      setLoading(true);
      const updatedDomains = previewData;
      let apiCall;
      switch(previewAction) {
        case 'Approval': apiCall = approveAprBulk; break;
        case 'Order': apiCall = orderDomainsBulk; break;
        case 'ZeroArchive': apiCall = zeroAndArchiveBulk; break;
        case 'SendBack': apiCall = sendBackBulk; break;
        default: apiCall = updateAprBulk;
      }

      // Make sure the API call exists and returns a Promise
      if (typeof apiCall !== 'function') {
        throw new Error(`Invalid action: ${previewAction}`);
      }

      await apiCall(updatedDomains, previewAction);
      
      setRowSelection({});
      setUpdatedAprDomains({});
      setLocalAprValues({});
      setSelectedAction('');
      setSnackbar({ 
        open: true, 
        message: getSnackbarMessage(previewAction), 
        severity: 'success' 
      });

      fetchDecisionHubData(mode, tabValue);
    } catch (error) {
      console.error('Error updating domains:', error);
      setSnackbar({ 
        open: true, 
        message: `Failed to ${previewAction.toLowerCase()} domains: ${error.message}`, 
        severity: 'error' 
      });
    } finally {
      setLoading(false);
      setPreviewDialogOpen(false);
    }
  };

  const handleOpenMultiSortDialog = () => {
    setMultiSortDialogOpen(true);
  };

  const handleCloseMultiSortDialog = () => {
    setMultiSortDialogOpen(false);
  };

  const handleApplyMultiSort = (sortCriteria) => {
    console.log("dddddddd",sortCriteria);
    table.setSorting(sortCriteria);
  };

  const handleZeroRecoToggle = (event) => {
    const newZeroRecoValue = event.target.checked;
    if (newZeroRecoValue && isNlDomain) {
      setIsNlDomain(false);
    }
    setIsZeroReco(newZeroRecoValue);
  };

  const handleNlDomainToggle = (event) => {
    const newNlDomainValue = event.target.checked;
    if (newNlDomainValue && isZeroReco) {
      setIsZeroReco(false);
    }
    setIsNlDomain(newNlDomainValue);
  };

  const previewColumns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'edate', 
      size: 50,
      accessorFn: (row) => {
        const date = row.edate;
        if (!date) return '-';
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? '-' : parsedDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    { 
      accessorKey: 'createdAt', 
      header: 'sdate', 
      size: 50,
      accessorFn: (row) => {
        const date = row.createdAt;
        if (!date) return '-';
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? '-' : parsedDate.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    { accessorKey: 'list', header: 'list', size: 40 },
    { accessorKey: 'domain', header: 'domain', size: 100 },
    { accessorKey: 'p', header: 'p', size: 30 },
    { accessorKey: 'bids', header: 'bids', size: 60 },
    { accessorKey: 'price', header: 'price', size: 60 },
    { accessorKey: 'est', header: 'est', size: 60 },
    { accessorKey: 'gdv', header: 'gdv', size: 60 },
    { accessorKey: 'esw', header: 'esw', size: 60 },
    { accessorKey: 'gdw', header: 'gdw', size: 60 },
    { 
      accessorKey: 'spr500', 
      header: 'spr500', 
      size: 60,
      Cell: ({ cell }) => `${(cell.getValue() * 100).toFixed(0)}%`
    },
    { accessorKey: 'reco', header: 'reco', size: 50 },
    { accessorKey: 'apr', header: 'apr', size: 50 },
  ], []);

  const previewData = useMemo(() => {

    const filteredData = (previewAction === 'Approval' || previewAction === 'Order')
      ? data.filter((item, index) => 
          rowSelection[index] && 
          (localAprValues[item.acquShortlistedDomain.id] > 0 || item.acquShortlistedDomain.apr > 0)
        )
      : data.filter((_, index) => rowSelection[index]);

    return filteredData.map(item => ({
      ...item.acquShortlistedDomain,
      apr: localAprValues[item.acquShortlistedDomain.id] ?? item.acquShortlistedDomain.apr,
      aprRemark: updatedAprDomains.aprRemark?.[item.acquShortlistedDomain.id] ?? item.acquShortlistedDomain.aprRemark
    }));
  }, [data, rowSelection, localAprValues, previewAction]);

  const previewTable = useMaterialReactTable({
    columns: previewColumns,
    data: previewData,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableTableHead: true,
    enableFilters: false,
    initialState: { density: 'compact' },
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: false,
    enableStickyHeader: true,
    enablePagination: false,

    muiTableProps: { 
      sx: { 
        tableLayout: 'fixed',
        "& .MuiTableCell-root": {
          padding: "4px 8px",
          fontSize: "0.6rem",
        },
      } 
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: '#000',
        fontWeight: 'bold',
        fontSize: '0.6rem', 
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.6rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '400px',
      },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
  });

  const handleKeyDown = (event, rowId, columnKey) => {
    const focusableColumns = ['apr','aprRemark']; // Add other editable columns if needed
    if (event.key === 'Enter') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const nextRow = data[currentRowIndex + 1];
      if (nextRow) {
        const nextInput = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${columnKey}"]`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      const currentRowIndex = data.findIndex(item => item.acquShortlistedDomain.id === rowId);
      const currentColumnIndex = focusableColumns.indexOf(columnKey);
      const nextColumnKey = focusableColumns[(currentColumnIndex + 1) % focusableColumns.length];
      const nextInput = document.querySelector(`input[data-row-id="${rowId}"][data-column-key="${nextColumnKey}"]`);
      if (nextInput) {
        nextInput.focus();
      } else {
        // Move to first column of next row
        const nextRow = data[currentRowIndex + 1];
        if (nextRow) {
          const firstInputInNextRow = document.querySelector(`input[data-row-id="${nextRow.acquShortlistedDomain.id}"][data-column-key="${focusableColumns[0]}"]`);
          if (firstInputInNextRow) {
            firstInputInNextRow.focus();
          }
        }
      }
    }
  };

  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'edate', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      enableSorting: true, 
     
     
      accessorFn: (row) => {
        const date = row.acquShortlistedDomain.edate;
        return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original.acquShortlistedDomain.edate;
        const dateB = rowB.original.acquShortlistedDomain.edate;
        
        // Handle cases where dates are null/undefined
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Convert dates to timestamps for comparison
        const timeA = new Date(dateA).getTime();
        const timeB = new Date(dateB).getTime();
        
        return timeA - timeB;
      }
    },
    { 
      accessorKey: 'createdAt', 
      header: 'sdate', 
      size: 60, 
      minSize: 60, 
      maxSize: 60,
      accessorFn: (row) => {
        const date = row.acquShortlistedDomain.createdAt;
        return date ? new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-') : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.original.acquShortlistedDomain.createdAt;
        const dateB = rowB.original.acquShortlistedDomain.createdAt;
       // console.log(dateA,dateB);

        // Handle cases where dates are null/undefined
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;

        // Convert dates to timestamps for comparison
        const timeA = new Date(dateA).getTime();
        const timeB = new Date(dateB).getTime();
        
        return timeA - timeB;
      }
    },
    { 
      accessorKey: 'list', 
      header: 'list', 
    
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.list;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'domain',
      header: 'domain',
      size: 120,
      minSize: 120,
      maxSize: 150,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.domain;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric',
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} placement="bottom">
          <Typography 
            variant="caption" 
            sx={{ 
              fontSize: '0.8rem',
              whiteSpace: 'normal', // Changed from nowrap to normal
              wordWrap: 'break-word', // Added to allow breaking long words
              overflow: 'visible', // Changed from hidden to visible
              display: 'block'
            }}
          >
            {cell.getValue()}
          </Typography>
        </Tooltip>
      )
    },
    { 
      accessorKey: 'p', 
      header: 'p', 
      size: 20, 
      minSize: 20, 
      maxSize: 30,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.p;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'len', 
      header: 'len', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.len;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'host', 
      header: 'host', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.host;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'wby',
      header: 'wby',
      size: 30,
      minSize: 30,
      maxSize: 40,
      accessorFn: (row) => {
        const wby = row.acquShortlistedDomain.wby;
        if (wby && wby.length >= 4) {
          const year = parseInt(wby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
      sortingFn: 'basic'
    },
    {
      accessorKey: 'aby',
      header: 'aby',
      size: 30,
      minSize: 30,
      maxSize: 40,
      accessorFn: (row) => {
        const aby = row.acquShortlistedDomain.aby;
        if (aby && aby.length >= 4) {
          const year = parseInt(aby.substring(0, 4), 10);
          const currentYear = new Date().getFullYear();
          return currentYear - year;
        }
        return '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'reg', 
      header: 'reg', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.reg;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'regx', 
      header: 'regx', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.regx;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    { 
      accessorKey: 'tld_count', 
      header: 'tldc',
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => row.acquShortlistedDomain.tld_count
    },
    { 
      accessorKey: 'tld_count_dev', 
      header: 'tldd',
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => row.acquShortlistedDomain.tld_count_dev
    },

    { 
      accessorKey: 'sg', 
      header: 'sg', 
      size: 40, 
      minSize: 40, 
      maxSize: 50,
      enableSorting: true, 
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.sg;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },

    
    { 
      accessorKey: 'cpc', 
      header: 'cpc', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const cpc = row.acquShortlistedDomain.cpc;
        return cpc !== undefined && cpc !== null ? cpc.toFixed(1) : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'bids', 
      header: 'bids', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.bids;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'price', 
      header: 'price', 
      size: 50, 
      minSize: 50, 
      maxSize: 60,
      accessorFn: (row) => {
        const price = row.acquShortlistedDomain.price;
        return price !== undefined && price !== null ? Math.round(price) : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'est', 
      header: 'est', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.est;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdv', 
      header: 'gdv', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdv;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'esw', 
      header: 'esw', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.esw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    { 
      accessorKey: 'gdw', 
      header: 'gdw', 
      size: 45, 
      minSize: 45, 
      maxSize: 50,
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.gdw;
        return value !== null && value !== undefined ? Math.round(value/100)*100 : '-';
      },
      sortingFn: 'basic'
    },
    {
      accessorKey: 'spr500',
      header: 's500',
      size: 35,
      minSize: 35,
      maxSize: 40,
      accessorFn: (row) => {
        const spr500 = row.acquShortlistedDomain.spr500;
        if (spr500 !== null && spr500 !== undefined) {
          return spr500;
        }
        return null;
      },
      sortingFn: 'basic',
      Cell: ({ cell }) => {
        const value = cell.getValue();
        return value !== null ? `${Math.round(value * 100)}%` : '-';
      }
    },
    {
      accessorKey: 'sprGdv',
      header: 'sgdv',
      size: 35,
      minSize: 35,
      maxSize: 40,
      accessorFn: (row) => {
        const sprGdv = row.acquShortlistedDomain.sprGdv;
        return sprGdv !== null && sprGdv !== undefined ? `${Math.round(sprGdv * 100)}%` : '-';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.acquShortlistedDomain.sprGdv;
        const b = rowB.acquShortlistedDomain.sprGdv;
        if (a === null || a === undefined) return 1;
        if (b === null || b === undefined) return -1;
        return a - b;
      }
    },
    { 
      accessorKey: 'reco', 
      header: 'reco', 
      size: 40, 
      minSize: 40, 
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          paddingLeft: '4px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => {
        const value = row.acquShortlistedDomain.reco;
        return value !== null && value !== undefined ? value : '-';
      },
      sortingFn: 'alphanumeric'
    },
    {
      accessorKey: 'apr',
      header: 'apr',
      size: 50,
      minSize: 50,
      maxSize: 60,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Plum color for 'reco' column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.apr,
      sortingFn: 'basic',
      Cell: ({ row }) => {
        const rowId = row.original.acquShortlistedDomain.id;
        const [localValue, setLocalValue] = useState(
          localAprValues[rowId] ?? row.original.acquShortlistedDomain.apr ?? ''
        );

        // Sync with external changes
        useEffect(() => {
          setLocalValue(localAprValues[rowId] ?? row.original.acquShortlistedDomain.apr ?? '');
        }, [rowId, localAprValues[rowId], row.original.acquShortlistedDomain.apr]);

        const handleChange = (e) => {
          const newValue = e.target.value;
          setLocalValue(newValue);
        };

        const handleBlur = () => {
          const numValue = Number(localValue);
          if (!isNaN(numValue)) {
            handleAprChange(rowId, numValue);
            setLocalAprValues(prev => ({
              ...prev,
              [rowId]: numValue
            }));
          }
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              type="number"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, rowId, 'apr')}
              inputProps={{
                'data-row-id': rowId,
                'data-column-key': 'apr',
                min: 0,
                style: { 
                  padding: '1px 4px', 
                  width: '30px', 
                  fontStyle: 'italic' 
                },
                sx: { fontSize: 10, height: 16 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                "& input[type=number]": { MozAppearance: "textfield" }
              }}
            />
          </Stack>
        );
      },
    },
    {
      accessorKey: 'leads',
      header: 'leads',
      size: 130,
      minSize: 130,
      maxSize: 150,
      Cell: ({ row }) => {
        const filteredLeads = row.original.socialLeads.filter(lead => !lead.isIrrelevant);
        return (
          <Box>
            {filteredLeads.map((lead, index) => {
              let domain;
              if (lead.domain) {
                try {
                  domain = new URL(lead.domain).hostname;
                  domain = domain.replace('www.', '');
                } catch (error) {
                  domain = lead.domain;
                }
              } else {
                domain = '-';
              }

              let snapshotUrl = '';
              if (lead.domainSnapShotUrl) {
                try {
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(lead.domainSnapShotUrl, "text/xml");
                  snapshotUrl = xmlDoc.getElementsByTagName("snapshot")[0]?.textContent || '';
                } catch (error) {
                  console.error('Error parsing snapshot URL:', error);
                }
              }

              const isActive = lead.leadStatus?.toLowerCase() === 'active';

              return (
                <Tooltip
                  key={index}
                  title={
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      maxWidth: '300px'
                    }}>
                      {snapshotUrl && (
                        <img
                          src={snapshotUrl}
                          alt="Domain Snapshot"
                          style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '200px',
                            objectFit: 'contain',
                            filter: !isActive ? 'grayscale(100%)' : 'none'
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 'medium',
                          wordBreak: 'break-all'
                        }}
                      >
                        {lead.domain || '-'}
                      </Typography>
                    </Box>
                  }
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        color: 'common.black',
                        border: '1px solid #ccc',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                        },
                        p: 1
                      }
                    }
                  }}
                >
                  <Box sx={{ 
                    width: '120px', // Fixed width container
                    overflow: 'hidden'
                  }}>
                    <Link
                      href={lead.domain.startsWith('http://') || lead.domain.startsWith('https://') ? lead.domain : `https://${lead.domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'block',
                        color: isActive ? 'primary.main' : 'text.secondary',
                        fontSize: '0.8rem',
                        textDecoration: 'none',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {domain}
                    </Link>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const a = rowA.original.socialLeads.length;
        const b = rowB.original.socialLeads.length;
        if(a==1&&b==1)
          return rowA.original.socialLeads[0].domain.length - rowB.original.socialLeads[0].domain.length;
        else
          return a - b;
      },
    },
    {
      accessorKey: 'socials',
      header: 'socials',
      size: 170,
      minSize: 170,
      maxSize: 180,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialLeads;
        
        const getUserId = (url) => {
          if (!url) return 'N/A';
          try {
            const urlObj = new URL(url);
            switch (true) {
              case url.includes('linkedin.com'):
                return urlObj.pathname.split('/')[2] || url;
              case url.includes('twitter.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              case url.includes('facebook.com'):
                return urlObj.pathname.split('/')[1] || url;
              case url.includes('instagram.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              default:
                return url;
            }
          } catch {
            return url || 'N/A';
          }
        };

        const formatCount = (count) => {
          if (count === null || count === undefined) return '    ';
          if (count === 0) return '0'.padStart(4, ' ');
          
          // For positive numbers, ensure minimum is 10
          if (count > 0 && count < 10) return '  10';
          
          // Get nearest power of 10
          const power = Math.round(Math.log10(count));
          const nearestPower = Math.pow(10, power);
          
          if (nearestPower >= 1000000) return `${(nearestPower / 1000000)}M`;
          if (nearestPower >= 1000) return `${(nearestPower / 1000)}K`;
          return nearestPower.toString().padStart(4, ' ');
        };

        const renderSocialIcons = (lead) => {
          // Get all social platforms from lead
          const availableSocials = lead?.socials || [];
          
          // Find Facebook and Instagram entries
          const fbSocial = availableSocials.find(s => s.platform.toLowerCase() === 'facebook');
          const igSocial = availableSocials.find(s => s.platform.toLowerCase() === 'instagram');
          
          // Choose the one with more followers
          let socialWithMoreFollowers;
          if (fbSocial && igSocial) {
            socialWithMoreFollowers = (fbSocial.followers || 0) >= (igSocial.followers || 0) ? fbSocial : igSocial;
          } else {
            socialWithMoreFollowers = fbSocial || igSocial;
          }
          
          // Create filtered socials array with LinkedIn, X, and the winning FB/IG
          const filteredSocials = [
            availableSocials.find(s => s.platform.toLowerCase() === 'linkedin') || { platform: 'linkedin' },
            availableSocials.find(s => s.platform.toLowerCase() === 'x') || { platform: 'x' },
            socialWithMoreFollowers || { platform: fbSocial ? 'facebook' : 'instagram' }
          ];

          // Extract email information
          const emailInfo = lead?.email;
          const emailIcon = emailInfo ? emailInfo.designation ? emailInfo.designation.charAt(0).toUpperCase() : 'o' : null;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Tooltip title={
                  emailInfo ? (
                    <Box>
                      <Typography variant="caption" sx={{ display: 'block' }}>
                        Email: {emailInfo.email}
                      </Typography>
                      <Typography variant="caption" sx={{ display: 'block' }}>
                        Name: {emailInfo.name}
                      </Typography>
                      <Typography variant="caption" sx={{ display: 'block' }}>
                        Designation: {emailInfo.designation}
                      </Typography>
                      <Typography variant="caption" sx={{ display: 'block' }}>
                        LinkedIn: {emailInfo.linkedinUrl}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      --
                    </Typography>
                  )
                }>
                <Box
                  sx={{
                    cursor: emailInfo?.linkedinUrl ? 'pointer' : 'not-allowed',
                    opacity: emailInfo?.linkedinUrl ? 1 : 0.5,
                    '& a': {
                      pointerEvents: emailInfo?.linkedinUrl ? 'auto' : 'none'
                    }
                  }}
                >
                  <Link
                    href={emailInfo?.linkedinUrl ? (emailInfo.linkedinUrl.startsWith('http://') || emailInfo.linkedinUrl.startsWith('https://') ? emailInfo.linkedinUrl : `https://${emailInfo.linkedinUrl}`) : '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '13px',
                      height: '13px',
                      borderRadius: '50%',
                      backgroundColor: emailInfo ? '#e0e0e0' : '#f0f0f0',
                      border: '1px solid #b0b0b0',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                      },
                      textDecoration: 'none'
                    }}
                  >
                    <Typography variant="caption" sx={{ fontSize: '0.6rem', fontWeight: 'bold', color: emailInfo ? 'inherit' : 'gray' }}>
                      {emailIcon || '-'}
                    </Typography>
                  </Link>
                  </Box>
                </Tooltip>
              {filteredSocials.map((social) => {
                let Icon, count, platform = social.platform.toLowerCase();

                switch (platform) {
                  case 'linkedin':
                    Icon = LinkedInIcon;
                    count = social.employees;
                    break;
                  case 'x':
                    Icon = XIcon;
                    count = social.followers;
                    break;
                  case 'facebook':
                    Icon = FacebookIcon;
                    count = social.followers;
                    break;
                  case 'instagram':
                    Icon = InstagramIcon;
                    count = social.followers;
                    break;
                  default:
                    return null;
                }

                const isActive = social.status && social.status.toLowerCase() === 'active';
                const iconColor = isActive ? 'primary.main' : 'action.disabled';
                const tooltipContent = `${getUserId(social.url)}${social.userFullName ? ` - ${social.userFullName}` : ''}`;

                return (
                  <Tooltip key={platform} title={tooltipContent}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      {social.url ? (
                        <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </Link>
                      ) : (
                        <>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          );
        };

        if (!socialLeads || socialLeads.length === 0) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Tooltip title="No copyright information available">
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <CopyrightIcon sx={{ 
                    fontSize: '0.9rem', 
                    color: '#FFB6C1', // Light pink color for missing copyright
                  }} />
                </Box>
              </Tooltip>
              {renderSocialIcons(null)}
            </Box>
          );
        }

        return (
          <Box>
            {socialLeads.map((lead, leadIndex) => (
              <Box key={leadIndex} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Tooltip 
                    title={
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block'}}>
                          Copyright Year: {lead?.copyright || 'Not Available'}
                        </Typography>
                        {lead?.description && (
                          <Typography variant="caption" sx={{ fontSize: '0.6rem', display: 'block', mt: 0.2 }}>
                            {lead.description}
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <CopyrightIcon sx={{ 
                        fontSize: '0.9rem', 
                        color: lead?.copyright 
                          ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                          : '#FFB6C1', // Light pink color for missing copyright
                      }} />
                    </Box>
                  </Tooltip>
                  {renderSocialIcons(lead)}
                </Box>
              </Box>
            ))}
          </Box>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const getActiveSocialCount = (socialLeads) => {
          return socialLeads.reduce((count, lead) => {
            return count + (lead.socials ? lead.socials.filter(social => 
              social.status && social.status.toLowerCase() === 'active'
            ).length : 0);
          }, 0);
        };
        const a = getActiveSocialCount(rowA.original.socialLeads);
        const b = getActiveSocialCount(rowB.original.socialLeads);
        return a - b;
      }
    },
    { 
      accessorKey: 'code', 
      header: 'code', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      sortingFn: 'alphanumeric',
      Cell: ({ row }) => {
        const initialCodeValue = useMemo(() => {
          const codeString = row.original.socialLeads.map(lead => lead.code || '').join('');
          return codeString || '-';
        }, [row.original.socialLeads]);
        return (
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.7rem',
              fontStyle: 'italic',
              padding: '1px 4px',
            }}
          >
            {initialCodeValue}
          </Typography>
        );
      },
    },
    { 
      accessorKey: 'active', 
      header: 'live', 
      size: 30, 
      minSize: 30, 
      maxSize: 40,
      accessorFn: (row) => {
        const isActive = row.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );
        return isActive ? '1' : '0';
      },
      sortingFn: (rowA, rowB, columnId) => {
        const isActiveA = rowA.original.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );
        const isActiveB = rowB.original.socialLeads.some(lead => 
          lead.socials && lead.socials.some(social => 
            social.status && social.status.toLowerCase() === 'active'
          )
        );
        return isActiveA === isActiveB ? 0 : isActiveA ? -1 : 1;
      }
    },
    {
      accessorKey: 'agents',
      header: 'agent',
      size: 50,
      minSize: 50,
      maxSize: 60,
      Cell: ({ row }) => {
        const agents = Object.entries(row.original.agents).flatMap(([key, value]) => {
          if (key === 'uploader' && Array.isArray(value)) {
            return value;
          }
          return value ? [value] : [];
        }).filter(agent => agent !== null);

        return (
          <Stack 
            direction="row" 
            alignItems="center"
            sx={{ 
              flexWrap: 'wrap',
              gap: '2px'
            }}
          >
            {agents?.map((agent, index) => {
              if (!agent) return null;

              // Handle cases where name might be an object or string
              let displayName = '';
              if (typeof agent.name === 'string') {
                displayName = agent.name;
              } else if (agent.name?.firstName && agent.name?.lastName) {
                displayName = `${agent.name.firstName} ${agent.name.lastName}`;
              }

              // Skip if we couldn't construct a valid display name
              if (!displayName) return null;

              // Get initials from display name
              const initials = displayName
                .split(' ')
                .filter(part => part.length > 0)
                .slice(0, 2)
                .map(part => part[0].toUpperCase())
                .join('');

              // Different background colors for different roles
              const getBackgroundColor = (role) => {
                switch (role) {
                  case 'Uploader':
                  case 'Shortlister': return '#e3f2fd';
                  case 'Processor': return '#f1f8e9';
                  case 'Recommender': return '#fff3e0';
                  case 'Approver': return '#fce4ec';
                  default: return '#f5f5f5';
                }
              };

              return (
                <Tooltip
                  key={`${index}-${displayName}`}
                  title={
                    <Box sx={{ p: 0.5 }}>
                      <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                        {displayName}
                      </Typography>
                      {agent.email && (
                        <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                          {agent.email}
                        </Typography>
                      )}
                      {agent.role && (
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            display: 'block', 
                            fontSize: '0.7rem',
                            fontStyle: 'italic',
                            mt: 0.5
                          }}
                        >
                          Role: {agent.role}
                        </Typography>
                      )}
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box
                    sx={{
                      backgroundColor: getBackgroundColor(agent.role),
                      border: '1px solid rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: 'medium',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        textAlign: 'center'
                      }}
                    >
                      {initials}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
      sortingFn: (rowA, rowB) => {
        const getAgentsString = (row) => {
          return Object.values(row.original.agents)
            .filter(agent => agent !== null)
            .map(agent => agent.name)
            .sort()
            .join(',');
        };
        
        return getAgentsString(rowA).localeCompare(getAgentsString(rowB));
      }
    },
    // { 
    //   accessorKey: 'remarks', 
    //   header: 'remark', 
    //   size: 30, 
    //   minSize: 30, 
    //   maxSize: 40,
    //   accessorFn: (row) => row.acquShortlistedDomain.remarks || '-',
    //   sortingFn: 'alphanumeric'
    // },
    {
      accessorKey: 'aprRemark',
      header: 'aRemark',
      size: 80,
      minSize: 80,
      maxSize: 90,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#D8BFD8', // Matching plum color from apr column
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      accessorFn: (row) => row.acquShortlistedDomain.aprRemark,
      sortingFn: 'alphanumeric',
      Cell: ({ row }) => {
        const rowId = row.original.acquShortlistedDomain.id;
        const [localValue, setLocalValue] = useState(
          updatedAprDomains.aprRemark?.[rowId] ?? 
          row.original.acquShortlistedDomain.aprRemark ?? 
          ''
        );

        // Sync with external changes
        useEffect(() => {
          setLocalValue(
            updatedAprDomains.aprRemark?.[rowId] ?? 
            row.original.acquShortlistedDomain.aprRemark ?? 
            ''
          );
        }, [rowId, updatedAprDomains.aprRemark, row.original.acquShortlistedDomain.aprRemark]);

        const handleChange = (e) => {
          setLocalValue(e.target.value);
        };

        const handleBlur = () => {
          handleAprRemarkChange(rowId, localValue);
        };

        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              size="small"
              value={localValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={(e) => handleKeyDown(e, rowId, 'aprRemark')}
              inputProps={{
                'data-row-id': rowId,
                'data-column-key': 'aprRemark',
                style: { 
                  padding: '1px 4px', 
                  width: '60px', // Slightly wider than apr field
                  fontStyle: 'italic' 
                },
                sx: { fontSize: 10, height: 16 }
              }}
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                padding: 0,
                margin: 0
              }}
            />
          </Stack>
        );
      }
    },
    {
      accessorKey: 'recoRemark',
      header: 'rRemark',
      size: 80,
      minSize: 80,
      maxSize: 80,
      Cell: ({ row }) => (
        <div className="px-2 py-1">
          {row.original.acquShortlistedDomain.recoRemark || ''}
        </div>
      )
    }
  ], [updatedAprDomains, handleAprChange, localAprValues, handleAprRemarkChange, handleKeyDown, data]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableColumnActions: false,
    enableColumnFilters: true,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    //layoutMode: 'grid',
    // getRowId: (row) => {if(row.acquShortlistedDomain)return row.acquShortlistedDomain.id;else return row.id},
    onRowSelectionChange: setRowSelection,
    state: { rowSelection, isLoading: loading},
    initialState: {
      density: 'compact',
      pagination: { pageSize: 200, pageIndex: 0 },
      sorting:[
        // {
        //     "id": "sg",
        //     "desc": true
        // }
      ],
      columnVisibility: {
        agents: false,
        remarks: false,
        createdAt: false,
        recoRemark: false,
        aprRemark: false,
      },
    },
    
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 300],
      showFirstButton: false,
      showLastButton: false,
    },
  

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const buttonSx = {
        textTransform: 'none',
        backgroundColor: '#8797A8',
        color: '#FFFFFF !important',
        padding: '4px 8px',
        minWidth: 'auto',
        '& .MuiButton-startIcon': {
          marginRight: '4px',
        },
        '&:hover': {
          backgroundColor: '#8797A8',
          opacity: 0.9,
          boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
        },
        '&.Mui-disabled': {
          backgroundColor: '#8797A8',
          opacity: 0.7,
        }
      };    
      return (
        <Stack direction="row" spacing={1} width="100%" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={0.5}>
            <Button
              size="small"
              startIcon={<SortIcon />}
              onClick={handleOpenMultiSortDialog}
              sx={buttonSx}
            >
              Sort
            </Button>
            {(isAdminUser || isEDManagerUser || isESManagerUser) && (
              <Button
                size="small"
                startIcon={<GridOnRoundedIcon />}
                onClick={() => setUpdateDialogOpen(true)}
                sx={buttonSx}
              >
                Update
              </Button>
            )}
            <Button
              size="small"
              startIcon={<ContentCopyOutlinedIcon />}
              onClick={() => handleCopyDomains(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Copy
            </Button>
            <Button
              size="small"
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={() => handleExportData(selectedRows)}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Export
            </Button>
            <Button
              size="small"
              startIcon={<SyncOutlinedIcon />}
              onClick={handleFetchStats}
              disabled={selectedRows.length === 0}
              sx={buttonSx}
            >
              Fetch Stats
            </Button>
            <Select
              size="small"
              value={selectedAction}
              onChange={handleActionChange}
              displayEmpty
              sx={{
                height: '34px',
                backgroundColor: '#8797A8',
                color: '#FFFFFF',
                '.MuiSelect-select': {
                  padding: '4px 8px',
                  fontSize: '0.85rem',
                  color: '#FFFFFF',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#8797A8',
                  opacity: 0.7,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '&:hover': {
                  backgroundColor: '#8797A8',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                  opacity: 0.9,
                },
              }}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <MenuItem value="" disabled>
                Select Action
              </MenuItem>
              <MenuItem value="Approval">Approve</MenuItem>
              <MenuItem value="Order">Order</MenuItem>
              <MenuItem value="ZeroArchive">Zero & Archive</MenuItem>
              <MenuItem value="SendBack">Send Back</MenuItem>
            </Select>
            <FormControlLabel
              control={
                <Switch
                  checked={isZeroReco}
                  onChange={handleZeroRecoToggle}
                  name="zeroReco"
                  color="primary"
                  size="small"
                  disabled={isNlDomain}
                />
              }
              label="0-Reco"
              sx={{
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.9rem',
                  marginLeft: '2px',
                },
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isNlDomain}
                  onChange={handleNlDomainToggle}
                  name="nlDomain"
                  color="primary"
                  size="small"
                  disabled={isZeroReco}
                />
              }
              label="NL-Domain"
              sx={{
                margin: 0,
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.9rem',
                  marginLeft: '2px',
                },
              }}
            />
          </Stack>
        </Stack>
      );
    },
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#FFC0CB',//'#e6e6fa',
        color: 'white',//'#000',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
  });

  const handleCopyDomains = useCallback((selectedRows) => {
    const domains = selectedRows.map(row => row.original.acquShortlistedDomain.domain).join('\n');
    navigator.clipboard.writeText(domains).then(() => {
      setSnackbar({ open: true, message: 'Domains copied to clipboard', severity: 'success' });
    }).catch(err => {
      setSnackbar({ open: true, message: 'Failed to copy domains', severity: 'error' });
    });
  }, []);

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Define which columns to export
      const exportColumns = [
        'domain', 'list', 'p', 'len', 'host', 'tld', 'wby', 'aby', 
        'reg', 'regx', 'sg', 'cpc', 'bids', 'price', 'est', 'gdv', 
        'esw', 'gdw', 'apr', 'remarks'
      ];

      // Create CSV header
      const header = exportColumns.join(',') + '\n';

      // Create CSV content from selected rows
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          const value = row.original.acquShortlistedDomain[col];
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      // Combine header and content
      const csv = header + csvContent;

      // Create and trigger download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `domains_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setSnackbar({ 
        open: true, 
        message: 'Data exported successfully', 
        severity: 'success' 
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({ 
        open: true, 
        message: 'Failed to export data', 
        severity: 'error' 
      });
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setRowSelection({});
    setLocalAprValues({});
    table.resetRowSelection();
    setPreviewAction('');
    setPreviewDialogOpen(false);

    setTabValue(newValue);
    // fetchDecisionHubData(mode, newValue);
  };

  useEffect(() => {
    return () => {
      setRowSelection({});
      setLocalAprValues({});
    };
  }, [data]);

  const handleClosePreviewDialog = () => {
    setPreviewDialogOpen(false);
    setSelectedAction('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%' >
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Decision Hub
          </Typography>
          <Box flexGrow={1}></Box>
          {(isAdminUser||(isEDManagerUser&&isESManagerUser)) && (
            <Button
              variant="outlined"
              onClick={() => {
                const newMode = mode === 'ed' ? 'es' : 'ed';
                setMode(newMode);
                fetchDecisionHubData(newMode, tabValue);
              }}
              sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
            >
              Switch To {mode === 'ed' ? 'ES' : 'ED'}
            </Button>
          )}
        </Stack>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {listTypes.map((listType) => (
                <Tab 
                  key={listType} 
                  label={listType}
                  value={listType}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {listTypes.map((listType) => (
            <TabPanel key={listType} value={listType} sx={{ padding: 1 }}>
              <Box>
                <MaterialReactTable table={table} />
              </Box>
            </TabPanel>
          ))}
        </TabContext>
        <Dialog 
          open={previewDialogOpen} 
          onClose={handleClosePreviewDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: '1.1rem', padding: '12px 16px' }}>
            Preview {previewAction}
          </DialogTitle>
          <DialogContent sx={{ padding: '8px 16px' }}>
            <Box sx={{ overflowX: 'auto' }}>
              <MaterialReactTable table={previewTable} />
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: '8px 16px', justifyContent: 'space-between' }}>
            <Button onClick={handleClosePreviewDialog} size="small" sx={{ marginRight: 'auto' }}>
              Cancel
            </Button>
            <Button onClick={handleFinalSubmit} variant="contained" color="primary" size="small">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <MultiSortDialog
          open={multiSortDialogOpen}
          onClose={handleCloseMultiSortDialog}
          columns={columns}
          initialSorting={table.getState().sorting}
          onApplySort={handleApplyMultiSort}
        />
        <UpdateShortlistedDomain 
          open={updateDialogOpen}
          handleClose={() => {
            setUpdateDialogOpen(false);
          }}
          onSuccess={() => {
            fetchDecisionHubData(mode, tabValue);
          }}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={2000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Stack>
    </ThemeProvider>
  );
}

export default DecisionHub;
