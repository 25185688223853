import { Alert, Box, Button, Snackbar, Stack, ThemeProvider, Typography } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import UploadCSVDialog from "./UploadCSVDialog";
import { getAllTeamsAndUsers, syncOwnedDomains, uploadDomains, uploadEmployees, uploadRegoDomains, uploadSocialEmployees, uploadWorkCustom } from "./api";
import { getUserName } from "./msalService";
import UploadCSVDialogWorkCustom from "./UploadCSVDialogWorkCustom";

export default function WorkForce() 
{
    const[openDialog, setOpenDialog]=useState(false);
    const[openWorkDialog, setOpenWorkDialog]=useState(false);

    const[openSocialDialog, setOpenSocialDialog]=useState(false);
    const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[employees,setEmployees]=useState([]);
    const[socialEmployees,setSocialEmployees]=useState([]);
    const [workdate, setWorkdate] = useState(null);
      const [processors, setProcessors] = useState([]);
      const [processor, setProcessor] = useState(null);
      const [work,setWork]=useState([]);
      const [domains,setDomains]=useState([]);
      const [ownedDomains,setOwnedDomains]=useState([]);
      const [openOwnedDomainDialog,setOpenOwnedDomainDialog]=useState(false);
      const [openDomainDialog,setOpenDomainDialog]=useState(false);
      useEffect(()=>{
        console.log(work);
        console.log(workdate);
        console.log(processor);

        if(work&&work.length>0&&workdate&&processor)
        uploadWorkCustom(work,workdate.format('YYYY-MM-DD'),processor).then((res)=>{setUpsuc(true)}).catch((err)=>{console.log(err);setUpfail(true)});
        
      },[work])

      const handleCloseDomain = () => {
        setOpenDomainDialog(false);
      };

      useEffect(()=>{
        if(domains&&domains.length>0)
        uploadRegoDomains(domains).then((res)=>{setUpsuc(true)}).catch((err)=>{console.log(err);setUpfail(true)});
      },[domains])

      useEffect(()=>{
        if(ownedDomains&&ownedDomains.length>0)
        syncOwnedDomains(ownedDomains).then((res)=>{setUpsuc(true)}).catch((err)=>{console.log(err);setUpfail(true)});
      },[ownedDomains])

    const handleClose = () => {
        setOpenDialog(false);
      };
      const handleCloseSocial = () => {
        setOpenSocialDialog(false);
      };
      const handleCloseOwnedDomain = () => {
        setOpenOwnedDomainDialog(false);
      };
      const handleCloseWork = () => {
        setOpenWorkDialog(false);
      };
      useEffect(()=>{console.log(employees);
      if(employees&&employees.length>0)
         uploadEmployees(employees).then((res)=>{setUpsuc(true)}).catch((err)=>{console.log(err);setUpfail(true)});
      if(socialEmployees&&socialEmployees.length>0)
         uploadSocialEmployees(socialEmployees).then((res)=>{setUpsuc(true)}).catch((err)=>{console.log(err);setUpfail(true)});
    },[employees,socialEmployees])

    useEffect(()=>{
      getAllTeamsAndUsers().then((res)=>{
          setProcessors(res.data.users);
      })
  },[])

    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Work-Force
    </Typography>
    <Box sx={{flexGrow:1}}/>
    {getUserName()=='yash@namekart.com'&&<>
    <Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenWorkDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Work
    </Button>
    <UploadCSVDialogWorkCustom title='Work' open={openWorkDialog} handleClose={handleCloseWork} setState={setWork} setWorkdate={setWorkdate} setProcessor={setProcessor} processors={processors} workdate={workdate} processor={processor} />
    <Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDomainDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Upload Reg-O Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openDomainDialog} handleClose={handleCloseDomain} setState={setDomains} />
    <Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenOwnedDomainDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Sync Owned Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openOwnedDomainDialog} handleClose={handleCloseOwnedDomain} setState={setOwnedDomains} />
    </>}
    <Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenSocialDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Social Employees
    </Button>
    <UploadCSVDialog title='Social Employees' open={openSocialDialog} handleClose={handleCloseSocial} setState={setSocialEmployees} />
     <Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true)}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Employees
    </Button>
    <UploadCSVDialog title='Employees' open={openDialog} handleClose={handleClose} setState={setEmployees} />
            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Employees Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Employees Not Uploaded!
        </Alert>
      </Snackbar>
            </Stack>
    </ThemeProvider>);
}