import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, Button, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { theme } from "../Theme";
import { isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst } from "../msalService";
import { useUser } from "../UserProvider";
import {
  getArchiveDomains,
  allocateShortlistedDomain,
  getSocialChildren,
  getEsArchiveDomains
} from "../api";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

export default function Archive() {
  const [domains, setDomains] = useState([]);
  const [tabValue, setTabValue] = useState('all');
  const [openAllocate, setOpenAllocate] = useState(false);
  const [allocateSuccess, setAllocateSuccess] = useState(false);
  const [allocateFail, setAllocateFail] = useState(false);
  const [agent, setAgent] = useState('');
  const [children, setChildren] = useState([]);
  const { user } = useUser();
  const [refresh, setRefresh] = useState(false);
  const [allocating, setAllocating] = useState(false);
  const [mode, setMode] = useState('ed');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [rowSelection, setRowSelection] = useState({});
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [loading, setLoading] = useState(false);

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isESAnalystUser = isESAnalyst();
  const isEDManagerUser = isEDManager();
  const isEDAnalystUser = isEDAnalyst();

  const processingStates = ['all', 'non-processing', 'processing', 'processed'];

  useEffect(() => {
    let initialMode = 'ed';
    if (isAdminUser || isEDManagerUser || isEDAnalystUser) {
      initialMode = 'ed';
    } else if (isESManagerUser || isESAnalystUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
    setLoading(true);
    fetchDomains(initialMode, tabValue)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));

    getSocialChildren().then(res => {
      setChildren(res.data);
    }).catch(err => console.log(err));
  }, [isAdminUser, isESManagerUser, isESAnalystUser, isEDManagerUser, isEDAnalystUser]);

  useEffect(() => {
    setFilteredDomains(domains);
  }, [domains]);

  const fetchDomains = (currentMode, processingState) => {
    setLoading(true);
    const apiCall = currentMode === 'ed' ? getArchiveDomains : getEsArchiveDomains;
    return apiCall(processingState)
      .then(res => {
        setDomains(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    fetchDomains(mode, newValue);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day}${month}`;
  };

  const formatYear = (dateString) => {
    if (!dateString) return '-';
    return dateString.split('-')[0];
  };

  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'EDate', 
      size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue())
    },
    { 
      accessorKey: 'createdAt', 
      header: 'SDate', 
      size: 100,
      Cell: ({ cell }) => formatDate(cell.getValue())
    },
    { accessorKey: 'list', header: 'List', size: 80 },
    { accessorKey: 'domain', header: 'Domain', size: 180 },
    { accessorKey: 'p', header: 'P', size: 60 },
    { accessorKey: 'len', header: 'LEN', size: 60 },
    { accessorKey: 'host', header: 'Host', size: 80 },
    { accessorKey: 'tld', header: 'TLD', size: 60 },
    { accessorKey: 'wby', 
      header: 'WBY', 
      size: 60,
      Cell: ({ cell }) => formatYear(cell.getValue())
    },
    { accessorKey: 'aby', 
      header: 'ABY', 
      size: 60,
      Cell: ({ cell }) => formatYear(cell.getValue())
    },
    { accessorKey: 'reg', header: 'REG', size: 60 },
    { accessorKey: 'regx', header: 'REGX', size: 60 },
    { accessorKey: 'sg', header: 'SG', size: 60 },
    { accessorKey: 'cpc', header: 'CPC', size: 60 },
    { accessorKey: 'bids', header: 'Bids', size: 60 },
    { accessorKey: 'price', header: 'Price', size: 80 },
    { accessorKey: 'est', header: 'EST', size: 80 },
    { accessorKey: 'gdv', header: 'GDV', size: 80 },
    { accessorKey: 'esw', header: 'ESW', size: 80 },
    { accessorKey: 'gdw', header: 'GDW', size: 80 },
    { 
      accessorKey: 'spr500', 
      header: 'SPR 500', 
      size: 80,
      accessorFn: (row) => {
        const spr500 = row.acquShortlistedDomain?.spr500 ?? row.spr500;
        if (spr500 !== null && spr500 !== undefined) {
          return `${Math.round(spr500 * 100)}%`;
        }
        return '-';
      }
    },
    { accessorKey: 'remarks', header: 'Remarks', size: 150 },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: filteredDomains,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.domain,
    initialState: { density: 'compact' },
    muiTableBodyProps: { sx: { backgroundColor: '#fcfcfa' } },
    muiTableBodyRowProps: { sx: { backgroundColor: '#fcfcfa', paddingY: 0 } },
    muiTablePaperProps: ({ table }) => ({
      sx: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    state: {
      pagination,
      rowSelection,
      isLoading: loading,
    },
    onPaginationChange: setPagination,
    
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: false,
    enableStickyHeader: true,

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        padding: '4px',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Archive
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {(isAdminUser || (isEDManagerUser && isESManagerUser)) && (
            <Button
              variant="outlined"
              onClick={() => {
                const newMode = mode === 'ed' ? 'es' : 'ed';
                setMode(newMode);
                fetchDomains(newMode, tabValue);
              }}
              sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
            >
              Switch To {mode === 'ed' ? 'ES' : 'ED'}
            </Button>
          )}
        </Stack>

        <Snackbar
          open={allocateSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Allocated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Allocation Failed!
          </Alert>
        </Snackbar>

        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {processingStates.map((state) => (
                <Tab 
                  key={state} 
                  label={state.charAt(0).toUpperCase() + state.slice(1)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value={tabValue} sx={{ padding: 1 }}>
            <MaterialReactTable table={table} />
          </TabPanel>
        </TabContext>

        <Dialog fullWidth maxWidth="sm" open={openAllocate} onClose={handleCloseAllocate}>
          <DialogTitle>
            <Typography variant="subtitle1">Allocate to Processor</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="body2">
              {Object.keys(rowSelection).length} domain{Object.keys(rowSelection).length !== 1 && 's'} selected
            </DialogContentText>
            <FormControl fullWidth margin="dense">
              <InputLabel id="processor-label">Select Agent</InputLabel>
              <Select
                labelId="processor-label"
                id="processor-select"
                value={agent}
                label="Select Processor"
                onChange={handleAgentChange}
                size="small"
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAllocate} size="small">Cancel</Button>
            <Button
              disabled={allocating}
              variant="contained"
              onClick={() => {
                setAllocating(true);
                allocateShortlistedDomain(Object.keys(rowSelection), agent).then(() => {
                  setAllocateSuccess(true);
                  setRefresh(!refresh);
                  setRowSelection({});
                  setAgent("");
                  handleCloseAllocate();
                  setAllocating(false);
                }).catch(() => {
                  setAllocateFail(true);
                  setAllocating(false);
                });
              }}
              size="small"
            >
              Allocate
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </ThemeProvider>
  );
}
