import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography, Checkbox, Badge } from "@mui/material";
import { theme } from "./Theme";
import React, { useEffect, useMemo, useState } from "react";
import UploadCSVDialog from "./UploadCSVDialog";
import { canAddOwned, isAdmin, isManager } from "./msalService";
import { Add, Edit, PanToolAltOutlined } from "@mui/icons-material";
import { allocateDomainToProcessor,uploadOwnedDomainss, uploadAldDomainss,allocateDomainToTeam, editDomain, editOwnedDomains, getAllOwnedDomain,getAllOwnedDomainsWithPagination, getAllTags, getRegistrars, uploadDomains, uploadOwnedDomains } from "./api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from "./UserProvider";
import UploadCSVDialogOwnedDomains from "./UploadCSVDialogOwnedDomains";

export default function MyDomainsOwned() 
{
   
//const arr=[];

      const[domains,setDomains]=useState([]);

      const[editDomains,setEditDomains]=useState([]);

      const[data,setData]=useState([]);
      const[tags,setTags]=useState([]);
      const[registrars,setRegistrars]=useState([]);
      const[regs,setRegs]=useState([]);
      const[registrar,setRegistrar]=useState("");
      const [selectedUsers, setSelectedUsers] = useState([]);

      const[openDialog, setOpenDialog]=useState(false);
      const[openDialog1, setOpenDialog1]=useState(false);
      const[upsuc, setUpsuc]=useState(false);
    const[upfail, setUpfail]=useState(false);
    const[upsuc1, setUpsuc1]=useState(false);
    const[upfail1, setUpfail1]=useState(false);
    const[upsuc2, setUpsuc2]=useState(false);
    const[upfail2, setUpfail2]=useState(false);

    let[idds, setIdds]= React.useState([]);
    const[openAllocate, setOpenAllocate]=useState(false);
    const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);
  const {user, updateUser} = useUser();
  const [checked, setChecked] = React.useState(true);

  const [pagination, setPagination] = useState({pageIndex: 0,pageSize: 50,});
  const [totalRowCount, setTotalRowCount] = useState(0);

  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getAllOwnedDomainsWithPagination({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize });
      setData(res.data.content);
      setTotalRowCount(res.data.totalElements);
    } catch (err) {
      console.log(err);
    }
  };

      const handleClose = () => {
          setOpenDialog(false);
        }

      const handleClose1 = () => {
        setOpenDialog1(false);
      }

      const handleProcessorChange = (event) => {
        setProcessor(event.target.value);
      };
    
      const handleTeamChange = (event) => {
        setTeam(event.target.value);
      };
      

          const handleCloseAllocate = () => {
            setOpenAllocate(false);
            setIdds([]);
            setSelectedUsers([]);
            setExistingDomains([]);
            setSelectedExistingDomains([]);
            setOpenExistingDomainsDialog(false);
          };
        
          const handleCloseExistingDomainsDialog = () => {
            setOpenExistingDomainsDialog(false);
            setSelectedExistingDomains([]);
            setExistingDomains([]);
            setDomains([]);
            if(idds.length>0){
            setOpenAllocate(true);
            }
          };
        
          const handleSelectAll = (event) => {
            setSelectedExistingDomains(event.target.checked ? existingDomains : []);
          };
        
          const handleExistingDomainSelect = (event) => {
            const { value } = event.target;
            setSelectedExistingDomains((prev) =>
              prev.includes(value)
                ? prev.filter((domain) => domain !== value)
                : [...prev, value]
            );
          };

          const handleUploadDomains = async (domains, registrar, overwrite = false) => {
            try {
                const res = await uploadOwnedDomainss(domains, registrar, overwrite);
                const newIds = res.data.savedDomainIds;
                setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));
        
                if (res.data.existingDomains.length > 0) {
                    setExistingDomains(res.data.existingDomains);
                    setOpenExistingDomainsDialog(true);
                } else {
                  setOpenAllocate(true);
                  setUpsuc(true);
                  setDomains([]);
                  handleCloseExistingDomainsDialog();
                }
                fetchData();
            } catch (err) {
                console.log(err);
                setDomains([]);
                setUpfail(true);
            }
          };
        
          useEffect(() => {
            if (domains && domains.length > 0 && registrar != ""){
              handleUploadDomains(domains, registrar)
            }
          }, [domains, registrar]);

      useEffect(() => {
        //console.log(editDomains);
        if (editDomains && editDomains.length > 0)
          editOwnedDomains(editDomains)
            .then((res) => {
              console.log(res);
              setUpsuc(true);
              setEditDomains([]);
              fetchData();
            })
            .catch((err) => {
              console.log(err);
              setUpfail(true);
            });
      }, [editDomains]);


      useEffect(() => {
        fetchData();
        getAllTags()
          .then((res) => {
            const arr = res.data.map((ar) => ar.tag);
            console.log(arr);
            setTags(arr);
          })
          .catch((err) => {
            console.log(err);
          });
        getRegistrars()
          .then((res) => {
            console.log(res.data);
            setRegistrars(res.data);
            const rr = res.data.map((r) => {
              return r.registrar;
            });
            setRegs(rr);
          })
          .catch((err) => {
            console.log(err);
          });
      }, [pagination.pageIndex, pagination.pageSize]);

      const isNewDomain = (dateString) => {
        const uploadDate = new Date(dateString);
        const currentDate = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
        return uploadDate > threeMonthsAgo;
      };
    

           const columns = useMemo(
            () => [
              // {
              //   id: 'dom', //id used to define `group` column
              //   header: 'Domain',
              //   columns: [
                  // {
                  //   accessorKey:'domain', //accessorFn used to join multiple data into a single cell
                  //   id: 'domain', //id is still required when using accessorFn instead of accessorKey
                  //   header: 'Domain',
                  //   // enableColumnFilterModes:false,
                  //   // filterFn: (row, id, filterValue) => { console.log(filterValue); return true; //return row.tags[0].tag === filterValue;
                  //   // },
                  //   //size: 250,
                    
                  // }
                  {
                    accessorKey: 'domain',
                    id: 'domain',
                    header: 'Domain',
                    Cell: ({ row }) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isNewDomain(row.original.dateOfUpload) ? (
                          <Badge
                            badgeContent="NEW" 
                            color="primary"
                            
                            
                            sx={{
                              '& .MuiBadge-badge': {
                                color:'white',
                                fontSize: '0.5rem',
                                height: '10px',
                                minWidth: '16px',
                                padding: '0 2px',
                              }
                            }}
                          >
                            <Box sx={{ pr: 2 }}>{row.original.domain}</Box>
                          </Badge>
                        ) : (
                          row.original.domain
                        )}
                      </Box>
                    ),
                  }
                  , {
                    id: 'dateOfUpload', //id is still required when using accessorFn instead of accessorKey
                    header: 'Add Date',
                    accessorFn: (originalRow) => { const date=new Date(originalRow.dateOfUpload);return date}, //convert to date for sorting and filtering
                    Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0], //format date for display
                    filterVariant: 'date-range',
                    enableEditing: false,
                    Edit:()=>null,
                    //size: 250,
                  }, {
                    id: 'allocatedTo',
                    header: 'Allocated To',
                    accessorFn: (row) => {
                      if (row.presentAllocation && row.presentAllocation.user) {
                        return `${row.presentAllocation.user.firstName} ${row.presentAllocation.user.lastName}`;
                      } else if (row.presentTeamAllocations && row.presentTeamAllocations.length > 0) {
                        return `${row.presentTeamAllocations[0].user.firstName} ${row.presentTeamAllocations[0].user.lastName}`;
                      } else {
                        return 'Not Allocated';
                      }
                    },
                  },{
                    accessorKey:'cost', //accessorFn used to join multiple data into a single cell
                    id: 'cost', //id is still required when using accessorFn instead of accessorKey
                    header: 'Cost',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'EST', //accessorFn used to join multiple data into a single cell
                    id: 'EST', //id is still required when using accessorFn instead of accessorKey
                    header: 'EST',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  },
                  {
                    accessorKey: 'GDV',
                    id: 'GDV',
                    //enableClickToCopy: true,
                    //filterVariant: 'autocomplete',
                    header: 'GDV',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                    //size: 300,
                  }, {
                    accessorKey:'salePrice', //accessorFn used to join multiple data into a single cell
                    id: 'salePrice', //id is still required when using accessorFn instead of accessorKey
                    header: 'Sale Price',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'reserve', //accessorFn used to join multiple data into a single cell
                    id: 'reserve', //id is still required when using accessorFn instead of accessorKey
                    header: 'Reserve',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'minOffer', //accessorFn used to join multiple data into a single cell
                    id: 'minOffer', //id is still required when using accessorFn instead of accessorKey
                    header: 'Min Offer',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  },
              //   ],
              // },
              // {
              //   id: 'categ', //id used to define `group` column
              //   header: 'Categories',
              //   columns: [
                  {
                    accessorKey:'category', //accessorFn used to join multiple data into a single cell
                    id: 'category', //id is still required when using accessorFn instead of accessorKey
                    header: 'Type',
                    //accessorFn: (row) => {if(row.brandable) return 'Brandable'; else return 'Generic';},
                    //size: 250,
                    
                  }, {
                    accessorKey:'categoryMain', //accessorFn used to join multiple data into a single cell
                    id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
                    header: 'Category',
                    //size: 250,
                    
                  }, {
                    accessorKey:'categorySedo', //accessorFn used to join multiple data into a single cell
                    id: 'categorySedo', //id is still required when using accessorFn instead of accessorKey
                    header: 'SEDO Category',
                    //size: 250,
                    
                  }, {
                    accessorKey:'categoryDan', //accessorFn used to join multiple data into a single cell
                    id: 'categoryDan', //id is still required when using accessorFn instead of accessorKey
                    header: 'DAN Category',
                    
                    //size: 250,
                    
                  }
                  , {
                    accessorKey:'tags', //accessorFn used to join multiple data into a single cell
                    //id: 'categoryMain', //id is still required when using accessorFn instead of accessorKey
                    enableColumnFilterModes:false,
                    filterVariant:'multi-select',
                    filterFn: (row, id, filterValue) => {if(filterValue.length==0) return true; else {for(let i=0;i<row.original.tags.length;i++){ if(filterValue.includes(row.original.tags[i].tag)) return true;} return false;}//return row.tags[0].tag === filterValue;
                    },
                    filterSelectOptions: tags,
                    header: 'Tags',
                    Cell: ({ cell }) => {if(cell.getValue().length>0) return cell.getValue()[0].tag+(cell.getValue().length>1?(cell.getValue().length-1):"")}, //format date for display
                    enableEditing: false,

                    //size: 250,
                    
                  },
              //   ],
              // },
              
              // {
              //   id: 'whois', //id used to define `group` column
              //   header: 'WhoIs',
              //   columns: [
                    {
                        //accessorKey:'type', //accessorFn used to join multiple data into a single cell
                        id: 'registrar', //id is still required when using accessorFn instead of accessorKey
                        header: 'Registrar',
                        accessorFn: (row) => {if(row.registrar) return row.registrar.displayName; else return 'N/A' },
                        editVariant: 'select',
        editSelectOptions: regs,

                        //size: 250,
                        
                      }, 
                    {
                    accessorKey:'whoIsEmail', //accessorFn used to join multiple data into a single cell
                    id: 'whoIsEmail', //id is still required when using accessorFn instead of accessorKey
                    header: 'WhoIs Email',
                    editVariant: 'select',
                    editSelectOptions: ['Namekart','Name.ai','Netherlands','Private','Other'],
                    //size: 250,
                    
                  }, {
                    accessorKey:'nameservers', //accessorFn used to join multiple data into a single cell
                    id: 'nameservers', //id is still required when using accessorFn instead of accessorKey
                    header: 'Nameservers',
                    Cell: ({ cell }) => {if(cell.getValue().length>0) return cell.getValue()[0].tag+(cell.getValue().length>1?(cell.getValue().length-1):"")}, //format date for display

                    //size: 250,
                    enableEditing: false,

                  },
              //   ],
              // },
              // {
              //   id: 'land', //id used to define `group` column
              //   header: 'Landers',
              //   columns: [
                  {
                    accessorKey:'lander', //accessorFn used to join multiple data into a single cell
                    id: 'lander', //id is still required when using accessorFn instead of accessorKey
                    header: 'Lander',
                    editVariant: 'select',
                    editSelectOptions: ['BuyNow','MakeOffer','RequestPrice','Other'],
                    //size: 250,
                    
                  }, {
                    accessorKey:'dan', //accessorFn used to join multiple data into a single cell
                    id: 'dan', //id is still required when using accessorFn instead of accessorKey
                    header: 'Dan',
                    editVariant: 'select',
                    editSelectOptions: ['Yes','No'],
                    //size: 250,
                    
                  }, {
                    accessorKey:'afternic', //accessorFn used to join multiple data into a single cell
                    id: 'afternic', //id is still required when using accessorFn instead of accessorKey
                    header: 'Afternic',
                    editVariant: 'select',
                    editSelectOptions: ['Yes','No','DLS'],
                    //size: 250,
                    
                  }
                  , {
                    accessorKey:'sedo', //accessorFn used to join multiple data into a single cell
                    id: 'sedo', //id is still required when using accessorFn instead of accessorKey
                    header: 'Sedo',
                    editVariant: 'select',
                    editSelectOptions: ['Yes','No','MLS'],
                    //size: 250,
                    
                  }, {
                    accessorKey:'dls', //accessorFn used to join multiple data into a single cell
                    id: 'dls', //id is still required when using accessorFn instead of accessorKey
                    header: 'Dls',
                    //size: 250,
                    editVariant: 'select',
        editSelectOptions: ['Yes','No'],
                  },
              //   ],
              // }
              // ,
              // {
              //   id: 'stats', //id used to define `group` column
              //   header: 'Stats',
              //   columns: [
                  {
                    accessorKey:'wby', //accessorFn used to join multiple data into a single cell
                    header: 'WBY',
                    //size: 250,
                    muiEditTextFieldProps: {
                      //type: 'number',
                    }
                  }, {
                    accessorKey:'aby', //accessorFn used to join multiple data into a single cell
                    header: 'ABY',
                    //size: 250,
                    muiEditTextFieldProps: {
                     // type: 'number',
                    }
                  }
                  , {
                    accessorKey:'eby', //accessorFn used to join multiple data into a single cell
                    header: 'EBY',
                    //size: 250,
                    muiEditTextFieldProps: {
                     // type: 'number',
                    }
                  }, {
                    accessorKey:'reg', //accessorFn used to join multiple data into a single cell
                    header: 'Reg',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }
                  , {
                    accessorKey:'regx', //accessorFn used to join multiple data into a single cell
                    header: 'Regx',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'sg', 
                    header: 'SG',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'cpc', 
                    header: 'CPC',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                    
                  },
              //   ],
              // },
              // {
              //   id: 'purchase', //id used to define `group` column
              //   header: 'Purchase',
              //   columns: [
                  {
                    accessorKey:'platform', //accessorFn used to join multiple data into a single cell
                    header: 'Platform',
                    //size: 250,
                    
                  }, {
                    accessorKey:'purchaseDate', //accessorFn used to join multiple data into a single cell
                    header: 'Date',
                    //accessorFn: (originalRow) => { const date=new Date(originalRow.dateOfUpload);return date}, //convert to date for sorting and filtering
                    //Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0], //format date for display
                    //size: 250,
                    
                  }, {
                    accessorKey:'PP', //accessorFn used to join multiple data into a single cell
                    header: 'Price',
                    //size: 250,
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                  }, {
                    accessorKey:'purchaseRenewal', //accessorFn used to join multiple data into a single cell
                    header: 'Renewal',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                    //size: 250,
                    
                  },
                  {
                    accessorKey: 'futureRenewal',
                    header: 'Future Renewal',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                    //size: 300,
                  }, {
                    accessorKey:'transferPrice', //accessorFn used to join multiple data into a single cell
                    header: 'Transfer Price',
                    muiEditTextFieldProps: {
                      type: 'number',
                    }
                    //size: 250,
                    
                  }, {
                    accessorKey:'authCode', //accessorFn used to join multiple data into a single cell
                    header: 'Auth Code',
                    //size: 250,
                    
                  }, {
                    accessorKey:'autoRenew', //accessorFn used to join multiple data into a single cell
                    accessorFn: (row) => {if(row.autoRenew) return 'Yes'; else return 'No' },

                    header: 'Auto Renew',
                    editVariant: 'select',
                    editSelectOptions: ["Yes",'No'],
                    //size: 250,
                  },
              //   ],
              // }
            ],
            [tags]
          );
        
          const domainTable=  useMaterialReactTable({
            columns:columns,
            data:data,
            //enableColumnFilterModes:true,
            //enableRowVirtualization:true,
            // enableColumnVirtualization:true,
            //rowVirtualizerOptions: { overscan: 50 },
            // columnVirtualizerOptions: { overscan: 5 },

            manualPagination: true,
            rowCount: totalRowCount,
            onPaginationChange: setPagination,
            state: { pagination },

            muiTableBodyRowProps: ({ row }) => {
              const isProcessor = row.original.presentAllocation && row.original.presentAllocation.user;
              const isTeam = row.original.presentTeamAllocations && row.original.presentTeamAllocations.length > 0;            
              const backgroundColor = isProcessor ? '#e0f7fa' : isTeam ? '#e8f5e9' : '#ffffff';           
              return {
                sx: {
                  backgroundColor,
                },
              };
            },  

            enableRowSelection: true,
            
            columnFilterDisplayMode: 'popover',
            muiPaginationProps: {
                color: 'secondary',
                rowsPerPageOptions: [50,150, 250, 500,1000],
                shape: 'rounded',
                variant: 'outlined',
              },
              enableEditing:isAdmin(),
                editDisplayMode: 'modal',
                onEditingRowSave: ({ values, table,row })=>{
                  editDomain(values,row.original.id).then((res)=>{console.log(res); setData((prev)=>{
                    let arr=data.map((row)=>{if(res.data.id==row.id) return res.data; else return row});
                    return arr;
                  })
                setUpsuc2(true)
                table.setEditingRow(null)
                }).catch((err)=>{console.log(err); setUpfail2(true)});
                  console.log(values);
                  
                  },
            initialState: { density: 'compact',
            // columnOrder: [
            //   ...columns.map(c => c.accessor || c.id), // assuming each column has an accessor or id
            //   'mrt-row-select' // move the built-in selection column to the end
            // ] 
          },
            renderTopToolbarCustomActions: ({ table }) => (
                <Stack direction='row-reverse' width='100%' alignItems='center'>
                {(isAdmin())&&<Button
              disabled={table.getSelectedRowModel().rows.length==0}

      variant="text"
      //color="primary"
      startIcon={<PanToolAltOutlined />}
      onClick={()=>{setOpenAllocate(true);
        console.log(table.getSelectedRowModel().rows);
        let id=[]
        id=table.getSelectedRowModel().rows.map((row)=>{return row.original.id})
    
        setIdds(id);
        console.log(id);}
    }
      sx={{
        textTransform:'none'
      }}>
      Allocate
    </Button>}<Box flexGrow={1} /></Stack>
              ),
              
            // //memoMode:'cells',
            // renderDetailPanel: ({ row }) => (
            //     <RenderDetailPanel row={row} addRowC={addRowC} subRowC={subRowC} setLeadName={setLeadName} addLeads={addLeads} setCf={setCf} setImp={setImp} addContactField={addContactField} subRowL={subRowL} calcLd={calcLd} />
            //   ),
            // enableExpandAll:true,
            muiTablePaperProps: ({ table }) => ({
                //not sx
                style: {
                  zIndex: table.getState().isFullScreen ? 1250 : undefined,
                },
              }),
            //   muiTableBodyRowProps:{sx:{backgroundColor:'whitesmoke'}},
            //   muiDetailPanelProps: ({ row }) => ({
            //     sx: {
            //       backgroundColor:'#fcfcfa',paddingX:0
            //     },
            //   }),
              
        
          });

          useEffect(()=>{
            console.log(domainTable.getState().sorting);
          },[domainTable.getState().sorting])
    return (<ThemeProvider theme={theme}>
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={2}>
            <Typography
      variant="h6"
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      My Domains
    </Typography>
    <Typography
      variant="h6"
      color='gray'
      sx={{
        textAlign: 'left',
        fontWeight: 'bold',
        position: 'relative',
      }}
    >
      Owned
    </Typography>
    <Box sx={{flexGrow:1}}/>
   
     {(isAdmin()||canAddOwned())&&<><Button
      variant="contained"
      color="primary"
      startIcon={<Add />}
      onClick={()=>{setOpenDialog(true);}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Add Domains
    </Button>
    <UploadCSVDialogOwnedDomains title='Domains' open={openDialog} handleClose={handleClose} setState={setDomains} setRegistrar={setRegistrar} registrars={registrars}/>

    <Button
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      onClick={()=>{setOpenDialog1(true);}}
      sx={{height:40,
        borderRadius:100,
        textTransform:'none'
      }}
    >
      Enrich Domains
    </Button>
    <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains}/></>}

            </Stack>
            <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc1(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail1(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpsuc2(false);}}>
        <Alert  severity="success" sx={{ width: '100%' }}>
          Changes Saved!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail2} autoHideDuration={2500} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={()=>{setUpfail2(false);}}>
        <Alert  severity="error" sx={{ width: '100%' }}>
          Changes Not Saved!
        </Alert>
      </Snackbar>
      <Box //sx={{ opacity: openbd ? 0.5 : 1,pointerEvents:openbd ? "none" : "auto"}}
      >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
      <DialogTitle>{<Stack direction='row' ><Typography variant="h6">Allocate to </Typography> <FormControlLabel sx={{p:0,m:0,ml:1}} control={<Switch checked={checked} onChange={(event)=>{setChecked(event.target.checked);}} size='small'/>} label={<Typography sx={{p:0,m:0}} variant="h6">{checked?'Processor':"Team"}</Typography>}  labelPlacement="start" /></Stack>}</DialogTitle>
      <DialogContent>
        <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
           {idds.length} domain{idds.length!=1&&'s'} selected
        </DialogContentText></Stack>
        <FormGroup sx={{mt:2}}>
<Stack direction='row' spacing={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="processor-label">Select Processor</InputLabel>
          <Select disabled={!checked}
            labelId="processor-label"
            id="processor-select"
            value={processor}
            label="Select Processor"
            onChange={handleProcessorChange}
          >
                        <MenuItem value={user.email}>Me</MenuItem>

            {user.users.map((child)=>{
              return <MenuItem value={child.email}>{child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="team-label">Select Team</InputLabel>
          <Select disabled={checked}
            labelId="team-label"
            id="team-select"
            value={team}
            label="Select Team"
            onChange={handleTeamChange}
          >
           {user.teams.map((child)=>{
              return <MenuItem value={child.email}>Team {child.firstName+" "+child.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAllocate}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            const allocationPromise = checked
              ? allocateDomainToProcessor(idds, processor)
              : allocateDomainToTeam(idds, team);

            allocationPromise
              .then(() => {
                setUpsuc1(true);
                fetchData(); // Refetch data after successful allocation
                // Reset state to initial values
                setProcessor([]);
                setTeam([]);
                setIdds([]);
              })
              .catch(() => {
                setUpfail1(true);
              })
              .finally(() => {
                handleCloseAllocate();
              });
          }}
        >
          Allocate
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog fullWidth open={openExistingDomainsDialog} onClose={handleCloseExistingDomainsDialog}>
            <DialogTitle>Existing Domains</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The following domains already exist and were not uploaded:
              </DialogContentText>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedExistingDomains.length === existingDomains.length}
                        onChange={handleSelectAll}
                      />
                    }
                    label="Select All"
                  />
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    {existingDomains.map((domain, index) => (
                      <Box key={index} width="30%" mb={2}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedExistingDomains.includes(domain)}
                              onChange={handleExistingDomainSelect}
                              value={domain}
                            />
                          }
                          label={domain}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseExistingDomainsDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={() => handleUploadDomains(selectedExistingDomains.map(domain => ({ domain })), registrar, true)}
              >
                Overwrite
              </Button>
            </DialogActions>
          </Dialog>
  
      <MaterialReactTable
     table={domainTable}
    />
    </LocalizationProvider>
    </Box>
            </Stack>
            </ThemeProvider>
    )
}