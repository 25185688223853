import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Stack, Tab, ThemeProvider, Typography, Button, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, InputLabel, MenuItem, Select,
  Tooltip,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Add, Star } from "@mui/icons-material";
import { theme } from "../Theme";
import { isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst } from "../msalService";
// import UploadCSVDialog from "../UploadCSVDialog";
import UploadCSVDialogShortlistedDomain from "../UploadCSVDialogShortlistedDomain";
import { useUser } from "../UserProvider";
import {
  getAcquShortlistedDomains,
  uploadShortlistedDomains,
  allocateShortlistedDomain,
  getSocialChildren,
  getTopLeadEmailAgent,
  getEsShortlistedDomains,
  fetchStats,
  getEsAnalystShortlistedDomains,
  moveToReco,
  moveToReasearch,
  moveToRecoEsAnalyst,
  moveToResearchEsAnalyst,
  allocateToSocialUser,
  allocateToEmailProcessor,
  moveToEmailCenter
} from "../api";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/Twitter'; // Assuming XIcon refers to Twitter
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CopyrightIcon from '@mui/icons-material/Copyright';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import EditLeadsDialog from '../AcquisitionCenter/EditLeadsDialog';
import UpdateShortlistedDomain from './UpdateShortlistedDomain';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import { FormControlLabel, Switch } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const getInitials = (firstName, lastName) => {
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

const currentYear = new Date().getFullYear();

export default function ShortlistedDomains() {
  const [domains, setDomains] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedListType, setSelectedListType] = useState("");
  const [tabValue, setTabValue] = useState('All');
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [allocateSuccess, setAllocateSuccess] = useState(false);
  const [allocateFail, setAllocateFail] = useState(false);
  const [newDomains, setNewDomains] = useState([]);
  const [agent, setAgent] = useState('');
  const [children, setChildren] = useState([]);
  const [emailAgent, setEmailAgent] = useState([]);
  const { user } = useUser();
  const [refresh, setRefresh] = useState(false);
  const [allocating, setAllocating] = useState(false);
  const [mode, setMode] = useState('ed');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [rowSelection, setRowSelection] = useState({});
  const [filteredDomains, setFilteredDomains] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', autoHideDuration: 2000 });
  const [researchTabValue, setResearchTabValue] = useState('All');
  const [domainResearchState, setDomainResearchState] = useState([]);
  const [domainTypeFilter, setDomainTypeFilter] = useState('All');
  const [openAllocateDialog, setOpenAllocateDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [openEmailAllocateDialog, setOpenEmailAllocateDialog] = useState(false);
  const [showDomainWithLeadsOnly, setShowDomainWithLeadsOnly] = useState(false);
  const [emailStateFilter, setEmailStateFilter] = useState('All');

  const isAdminUser = isAdmin();
  const isESManagerUser = isESManager();
  const isESAnalystUser = isESAnalyst();
  const isEDManagerUser = isEDManager();
  const isEDAnalystUser = isEDAnalyst();

  useEffect(() => {
    let initialMode = 'ed';

    if (isAdminUser||isEDManagerUser || isEDAnalystUser) {
      initialMode = 'ed'; // Default for admin, can be changed via UI
    } else if (isESManagerUser || isESAnalystUser) {
      initialMode = 'es';
    } 
    setMode(initialMode);
    setLoading(true);
    fetchDomains(initialMode).finally(() => setLoading(false));

    getSocialChildren().then(res => {
      setChildren(res.data);
    }).catch(err => console.log(err));

    getTopLeadEmailAgent().then(res => {
      setEmailAgent(res.data);
    }).catch(err => console.log(err));
   
  }, [isAdminUser, isESManagerUser, isESAnalystUser, isEDManagerUser, isEDAnalystUser]);

  const [fetchingStats, setFetchingStats] = useState(false);
  const [activeView, setActiveView] = useState("adminView");
  
  useEffect(() => {
    setActiveView(getActiveView());
  },[mode, isAdmin, isESManager, isESAnalyst, isEDManager, isEDAnalyst]);

  const getActiveView = () => {
    if (isAdmin()) {
      return 'adminView';
    }
    if (isESManager() || isEDManager()) {
      return 'managerialView';
    }
    if (isESAnalyst() && mode === 'es') {
      return 'esAnalystView';
    }
    return 'adminView';
  };

  useEffect(() => {
    if (newDomains && newDomains.length > 0) {
      console.log(newDomains);
      uploadShortlistedDomains(newDomains).then(() => {
        setUploadSuccess(true);
        setNewDomains([]);
        fetchDomains(mode);
        setRefresh(!refresh);
      }).catch(err => {
        console.log(err);
        setUploadFail(true);
      });
    }
  }, [newDomains]);

  const getEmailState = (domain) => {
    const socialLeads = domain.socialDomain?.socialWorks?.[0]?.topLeads || [];
    const totalLeads = socialLeads.length;
    
    if (totalLeads === 0) return 'no_leads';

    let leadsSubmitted = 0;
    let leadsInProgress = 0;
    let leadsNotStarted = 0;
    let unallocated = 0;

    socialLeads.forEach(lead => {
      if (!lead.presentAllocation) {
        unallocated++;
      } else {
        switch(lead.presentAllocation.status) {
          case 'submitted':
            leadsSubmitted++;
            break;
          case 'in progress':
            leadsInProgress++;
            break;
          case 'not started':
            leadsNotStarted++;
            break;
          default:
            leadsNotStarted++;
        }
      }
    });

    if (unallocated === totalLeads) return 'unallocated';
    if (leadsSubmitted === totalLeads) return 'done';
    if (leadsSubmitted > 0 || leadsInProgress > 0) return 'processing';
    if (leadsNotStarted === totalLeads) return 'not_started';
    return 'pending';
  };

  useEffect(() => {
    let filtered = domains;
    
    // Filter by tab value
    if (tabValue !== 'All') {
      filtered = filtered.filter(domain => domain.list === tabValue);
    }
    
    // Filter by domain type
    if (domainTypeFilter !== 'All') {
      filtered = filtered.filter(domain => domain.domainType === domainTypeFilter);
    }
    
    // Filter by email state
    if (emailStateFilter !== 'All') {
      filtered = filtered.filter(domain => getEmailState(domain) === emailStateFilter);
    }
    
    setFilteredDomains(filtered);
    table.resetRowSelection();
  }, [tabValue, domains, domainTypeFilter, emailStateFilter]);

  useEffect(() => {
    fetchDomains(mode);
  }, [researchTabValue]);

  useEffect(() => {
    fetchDomains(mode);
  }, [showDomainWithLeadsOnly]);

  const fetchDomains = async (currentMode) => {
    setLoading(true);
    let apiCall;
    if (activeView === "esAnalystView") {
      apiCall = getEsAnalystShortlistedDomains;
    } else {
      apiCall = currentMode === 'ed' ? getAcquShortlistedDomains : getEsShortlistedDomains;
    }
    try {
      try {
        const res = await apiCall(researchTabValue, showDomainWithLeadsOnly);
        setDomains(res.data);
      } catch (err) {
        return console.log(err);
      }
    } finally {
      return setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day}${month}`;
  };

  const formatYear = (dateString) => {
    if (!dateString) return '-';
    return dateString.split('-')[0];
  };
  
  const columns = useMemo(() => [
    { 
      accessorKey: 'edate', 
      header: 'EDate', 
      size: 100,
      accessorFn: (row) => {
        const date = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.edate 
          : row.edate;
        return formatDate(date);
      }
    },
    { 
      accessorKey: 'createdAt', 
      header: 'SDate', 
      size: 100,
      accessorFn: (row) => {
        const date = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.createdAt 
          : row.createdAt;
        return formatDate(date);
      }
    },
    { 
      accessorKey: 'domain', 
      header: 'Domain', 
      size: 180,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.domain 
        : row.domain
    },
    { 
      accessorKey: 'list', 
      header: 'List', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.list 
        : row.list
    },
    { 
      accessorKey: 'p', 
      header: 'P', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.p 
        : row.p
    },
    { 
      accessorKey: 'len', 
      header: 'LEN', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.len 
        : row.len
    },
    { 
      accessorKey: 'host', 
      header: 'Host', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.host 
        : row.host
    },
    { 
      accessorKey: 'tld', 
      header: 'TLD', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.tld 
        : row.tld
    },
    { 
      accessorKey: 'wby', 
      header: 'WBY', 
      size: 60,
      accessorFn: (row) => {
        const year = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.wby 
          : row.wby;
        return formatYear(year);
      }
    },
    { 
      accessorKey: 'aby', 
      header: 'ABY', 
      size: 60,
      accessorFn: (row) => {
        const year = activeView === "esAnalystView" 
          ? row.shortlistedDomain?.aby 
          : row.aby;
        return formatYear(year);
      }
    },
    { 
      accessorKey: 'reg', 
      header: 'REG', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.reg 
        : row.reg
    },
    { 
      accessorKey: 'regx', 
      header: 'REGX', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.regx 
        : row.regx
    },
    { 
      accessorKey: 'sg', 
      header: 'SG', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.sg 
        : row.sg
    },
    { 
      accessorKey: 'cpc', 
      header: 'CPC', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.cpc 
        : row.cpc
    },
    { 
      accessorKey: 'bids', 
      header: 'Bids', 
      size: 60,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.bids 
        : row.bids
    },
    { 
      accessorKey: 'price', 
      header: 'Price', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.price 
        : row.price
    },
    { 
      accessorKey: 'est', 
      header: 'EST', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.est 
        : row.est
    },
    { 
      accessorKey: 'gdv', 
      header: 'GDV', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.gdv 
        : row.gdv
    },
    { 
      accessorKey: 'esw', 
      header: 'ESW', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.esw 
        : row.esw
    },
    { 
      accessorKey: 'gdw', 
      header: 'GDW', 
      size: 80,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.gdw 
        : row.gdw
    },
    { 
      accessorKey: 'spr500', 
      header: 'SPR 500', 
      size: 80,
      Cell: ({ row }) => {
        const value = activeView === "esAnalystView" 
          ? row.original.shortlistedDomain?.spr500 
          : row.original.spr500;
        return value !== null ? `${(value * 100).toFixed(2)}%` : '-';
      }
    },
    { 
      accessorKey: 'remarks', 
      header: 'Remarks', 
      size: 150,
      accessorFn: (row) => activeView === "esAnalystView" 
        ? row.shortlistedDomain?.remarks 
        : row.remarks
    },
    {
      accessorKey: 'leads',
      header: 'leads',
      size: 130,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialDomain?.socialWorks?.[0]?.topLeads || [];

        return (
          <Box>
            {socialLeads.map((lead, index) => {
              let domain;
              if (lead.domain) {
                try {
                  domain = new URL(lead.domain).hostname;
                  domain = domain.replace('www.', '');
                } catch (error) {
                  domain = lead.domain;
                }
              } else {
                domain = '-';
              }

              let snapshotUrl = '';
              if (lead.domainSnapShotUrl) {
                try {
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(lead.domainSnapShotUrl, "text/xml");
                  snapshotUrl = xmlDoc.getElementsByTagName("snapshot")[0]?.textContent || '';
                } catch (error) {
                  console.error('Error parsing snapshot URL:', error);
                }
              }

              const isActive = lead.leadStatus?.toLowerCase() === 'active';

              return (
                <Tooltip
                  key={index}
                  title={
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      maxWidth: '300px'
                    }}>
                      {snapshotUrl && (
                        <img
                          src={snapshotUrl}
                          alt="Domain Snapshot"
                          style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '200px',
                            objectFit: 'contain',
                            filter: !isActive ? 'grayscale(100%)' : 'none'
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 'medium',
                          wordBreak: 'break-all'
                        }}
                      >
                        {lead.domain || '-'}
                      </Typography>
                    </Box>
                  }
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        color: 'common.black',
                        border: '1px solid #ccc',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                        },
                        p: 1
                      }
                    }
                  }}
                >
                  <Box sx={{ width: '120px', overflow: 'hidden' }}>
                    <Link
                      href={lead.domain ? (lead.domain.startsWith('http') ? lead.domain : `https://${lead.domain}`) : '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'block',
                        color: isActive ? 'primary.main' : 'text.secondary',
                        fontSize: '0.8rem',
                        textDecoration: 'none',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {domain}
                    </Link>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        );
      },
    },
    {
      accessorKey: 'socials',
      header: 'socials',
      size: 150,
      minSize: 150,
      maxSize: 180,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialDomain?.socialWorks?.[0]?.topLeads || [];

        const getUserId = (url) => {
          if (!url) return 'N/A';
          try {
            const urlObj = new URL(url);
            switch (true) {
              case url.includes('linkedin.com'):
                return urlObj.pathname.split('/')[2] || url;
              case url.includes('twitter.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              case url.includes('facebook.com'):
                return urlObj.pathname.split('/')[1] || url;
              case url.includes('instagram.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              default:
                return url;
            }
          } catch {
            return url || 'N/A';
          }
        };

        const formatCount = (count) => {
          if (count === null || count === undefined) return '    ';
          if (count === 0) return '0'.padStart(4, ' ');
          
          // For positive numbers, ensure minimum is 10
          if (count > 0 && count < 10) return '  10';
          
          // Get nearest power of 10
          const power = Math.round(Math.log10(count));
          const nearestPower = Math.pow(10, power);
          
          if (nearestPower >= 1000000) return `${(nearestPower / 1000000)}M`;
          if (nearestPower >= 1000) return `${(nearestPower / 1000)}K`;
          return nearestPower.toString().padStart(4, ' ');
        };

        const renderSocialIcons = (lead) => {
          const availableSocials = lead?.socials || [];
          const fbSocial = availableSocials.find(s => s.platform.toLowerCase() === 'facebook');
          const igSocial = availableSocials.find(s => s.platform.toLowerCase() === 'instagram');
          let socialWithMoreFollowers;
          if (fbSocial && igSocial) {
            socialWithMoreFollowers = (fbSocial.followers || 0) >= (igSocial.followers || 0) ? fbSocial : igSocial;
          } else {
            socialWithMoreFollowers = fbSocial || igSocial;
          }
          const filteredSocials = [
            availableSocials.find(s => s.platform.toLowerCase() === 'linkedin') || { platform: 'linkedin' },
            availableSocials.find(s => s.platform.toLowerCase() === 'x') || { platform: 'x' },
            socialWithMoreFollowers || { platform: fbSocial ? 'facebook' : 'instagram' }
          ];

          const emailInfo = lead?.emailWorks?.[0]?.emails?.[0];
          const emailIcon = emailInfo ? emailInfo.designation.charAt(0).toUpperCase() : null;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Tooltip title={
                emailInfo ? (
                  <Box>
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      Email: {emailInfo.email}
                    </Typography>
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      Name: {emailInfo.name}
                    </Typography>
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      Designation: {emailInfo.designation}
                    </Typography>
                    <Typography variant="caption" sx={{ display: 'block' }}>
                      LinkedIn: {emailInfo.linkedinUrl}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="caption" sx={{ display: 'block' }}>
                    --
                  </Typography>
                )
              }>
                <Box
                  sx={{
                    pointerEvents: emailInfo?.linkedinUrl ? 'auto' : 'none',
                    opacity: emailInfo?.linkedinUrl ? 1 : 0.5,
                  }}
                >
                  <Link
                    href={emailInfo?.linkedinUrl ? (emailInfo.linkedinUrl.startsWith('http://') || emailInfo.linkedinUrl.startsWith('https://') ? emailInfo.linkedinUrl : `https://${emailInfo.linkedinUrl}`) : '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '13px',
                      height: '13px',
                      borderRadius: '50%',
                      backgroundColor: emailInfo ? '#e0e0e0' : '#f0f0f0',
                      border: '1px solid #b0b0b0',
                      '&:hover': {
                        cursor: emailInfo?.linkedinUrl ? 'pointer' : 'not-allowed',
                        opacity: emailInfo?.linkedinUrl ? 0.8 : 0.5,
                      },
                      textDecoration: 'none'
                    }}
                  >
                    <Typography variant="caption" sx={{ fontSize: '0.6rem', fontWeight: 'bold', color: emailInfo ? 'inherit' : 'gray' }}>
                      {emailIcon || '-'}
                    </Typography>
                  </Link>
                </Box>
              </Tooltip>
              {filteredSocials.map((social) => {
                let Icon, count, platform = social.platform.toLowerCase();
                switch (platform) {
                  case 'linkedin':
                    Icon = LinkedInIcon;
                    count = social.employees;
                    break;
                  case 'x':
                    Icon = XIcon;
                    count = social.followers;
                    break;
                  case 'facebook':
                    Icon = FacebookIcon;
                    count = social.followers;
                    break;
                  case 'instagram':
                    Icon = InstagramIcon;
                    count = social.followers;
                    break;
                  default:
                    return null;
                }
                const isActive = social.status && social.status.toLowerCase() === 'active';
                const iconColor = isActive ? 'primary.main' : 'action.disabled';
                const tooltipContent = `${getUserId(social.url)}${social.userFullName ? ` - ${social.userFullName}` : ''}`;

                return (
                  <Tooltip key={platform} title={tooltipContent}>
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      {social.url ? (
                        <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </Link>
                      ) : (
                        <>
                          <Icon sx={{ fontSize: '0.9rem', color: iconColor }} />
                          <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                            {formatCount(count)}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          );
        };

        if (!socialLeads || socialLeads.length === 0) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Tooltip title="No copyright information available">
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <CopyrightIcon sx={{ 
                    fontSize: '0.9rem', 
                    color: '#FFB6C1', // Light pink color for missing copyright
                  }} />
                </Box>
              </Tooltip>
              {renderSocialIcons(null)}
            </Box>
          );
        }

        return (
          <Box>
            {socialLeads.map((lead, leadIndex) => (
              <Box key={leadIndex} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Tooltip 
                    title={
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block'}}>
                          Copyright Year: {lead?.copyright || 'Not Available'}
                        </Typography>
                        {lead?.description && (
                          <Typography variant="caption" sx={{ fontSize: '0.6rem', display: 'block', mt: 0.2 }}>
                            {lead.description}
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                      <CopyrightIcon sx={{ 
                        fontSize: '0.9rem', 
                        color: lead?.copyright 
                          ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                          : '#FFB6C1', // Light pink color for missing copyright
                      }} />
                    </Box>
                  </Tooltip>
                  {renderSocialIcons(lead)}
                </Box>
              </Box>
            ))}
          </Box>
        );
      }
    },
    {
      accessorKey: 'agents',
      header: 'agent',
      size: 80,
      minSize: 80,
      maxSize: 100,
      Cell: ({ row }) => {
        // Collect all agents with their roles
        const agents = [];

        // Add user (Shortlister/Uploader)
        if (mode === 'es') {
          if (row.original.aqShortlistings?.length > 0) {
            row.original.aqShortlistings.forEach(shortlisting => {
              if (shortlisting.user) {
                agents.push({
                  name: `${shortlisting.user.firstName} ${shortlisting.user.lastName}`,
                  email: shortlisting.user.email,
                  role: 'Shortlister'
                });
              }
            });
          }
        } else {
          if (row.original.user) {
            agents.push({
              name: `${row.original.user.firstName} ${row.original.user.lastName}`,
              email: row.original.user.email,
              role: 'Uploader'
            });
          }
        }

        // Add processor (from presentAllocation)
        if (row.original.socialDomain?.presentAllocation?.user) {
          const processor = row.original.socialDomain.presentAllocation.user;
          agents.push({
            name: `${processor.firstName} ${processor.lastName}`,
            email: processor.email,
            role: 'Processor'
          });
        }

        // Add recommender
        if (row.original.recoBy) {
          agents.push({
            name: typeof row.original.recoBy === 'string'
              ? row.original.recoBy
              : `${row.original.recoBy.firstName} ${row.original.recoBy.lastName}`,
            email: row.original.recoBy?.email,
            role: 'Recommender'
          });
        }

        // Add approver
        if (row.original.aprUnaprBy) {
          agents.push({
            name: typeof row.original.aprUnaprBy === 'string'
              ? row.original.aprUnaprBy
              : `${row.original.aprUnaprBy.firstName} ${row.original.aprUnaprBy.lastName}`,
            email: row.original.aprUnaprBy?.email,
            role: 'Approver'
          });
        }

        if (agents.length === 0) return '-';

        return (
          <Stack 
            direction="row" 
            spacing={0.25}
            alignItems="center"
            sx={{ 
              flexWrap: 'wrap',
            }}
          >
            {agents.map((agent, index) => {
              if (!agent?.name) return null;

              const initials = agent.name
                .split(' ')
                .filter(part => part.length > 0)
                .slice(0, 2)
                .map(part => part[0].toUpperCase())
                .join('');

              const getBackgroundColor = (role) => {
                switch (role) {
                  case 'Uploader':
                  case 'Shortlister': return '#e3f2fd';
                  case 'Processor': return '#f1f8e9';
                  case 'Recommender': return '#fff3e0';
                  case 'Approver': return '#fce4ec';
                  default: return '#f5f5f5';
                }
              };

              return (
                <Tooltip
                  key={`${index}-${agent.name}`}
                  title={
                    <Box sx={{ p: 0.5 }}>
                      <Typography variant="caption" sx={{ display: 'block', fontWeight: 'bold' }}>
                        {agent.name}
                      </Typography>
                      {agent.email && (
                        <Typography variant="caption" sx={{ display: 'block', fontSize: '0.7rem' }}>
                          {agent.email}
                        </Typography>
                      )}
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          display: 'block', 
                          fontSize: '0.7rem',
                          fontStyle: 'italic',
                          mt: 0.5
                        }}
                      >
                        Role: {agent.role}
                      </Typography>
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box
                    sx={{
                      backgroundColor: getBackgroundColor(agent.role),
                      border: '1px solid rgba(0,0,0,0.1)',
                      borderRadius: '50%',
                      width: '18px',
                      height: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&:hover': {
                        cursor: 'pointer',
                        opacity: 0.8,
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.6rem',
                        fontWeight: 'medium',
                        fontStyle: 'italic',
                        lineHeight: 1,
                        textAlign: 'center'
                      }}
                    >
                      {initials}
                    </Typography>
                  </Box>
                </Tooltip>
              );
            })}
          </Stack>
        );
      }
    },
    {
      accessorKey: 'actions',
      header: 'Review',
      size: 50,
      minSize: 50,
      maxSize: 60,
      Cell: ({ row }) => {
        const transformedData = {
          acquShortlistedDomain: {
            id: activeView === "esAnalystView" ? row.original.shortlistedDomain?.id : row.original.id,
            domain: activeView === "esAnalystView" ? row.original.shortlistedDomain?.domain : row.original.domain,
            list: activeView === "esAnalystView" ? row.original.shortlistedDomain?.list : row.original.list,
            host: activeView === "esAnalystView" ? row.original.shortlistedDomain?.host : row.original.host,
            tld: activeView === "esAnalystView" ? row.original.shortlistedDomain?.tld : row.original.tld,
            wby: activeView === "esAnalystView" ? row.original.shortlistedDomain?.wby : row.original.wby,
            aby: activeView === "esAnalystView" ? row.original.shortlistedDomain?.aby : row.original.aby,
            reg: activeView === "esAnalystView" ? row.original.shortlistedDomain?.reg : row.original.reg,
            regx: activeView === "esAnalystView" ? row.original.shortlistedDomain?.regx : row.original.regx,
            sg: activeView === "esAnalystView" ? row.original.shortlistedDomain?.sg : row.original.sg,
            cpc: activeView === "esAnalystView" ? row.original.shortlistedDomain?.cpc : row.original.cpc,
            bids: activeView === "esAnalystView" ? row.original.shortlistedDomain?.bids : row.original.bids,
            price: activeView === "esAnalystView" ? row.original.shortlistedDomain?.price : row.original.price,
            est: activeView === "esAnalystView" ? row.original.shortlistedDomain?.est : row.original.est,
            gdv: activeView === "esAnalystView" ? row.original.shortlistedDomain?.gdv : row.original.gdv,
            p: activeView === "esAnalystView" ? row.original.shortlistedDomain?.p : row.original.p,
            len: activeView === "esAnalystView" ? row.original.shortlistedDomain?.len : row.original.len,
            esw: activeView === "esAnalystView" ? row.original.shortlistedDomain?.esw : row.original.esw,
            gdw: activeView === "esAnalystView" ? row.original.shortlistedDomain?.gdw : row.original.gdw,
            remarks: activeView === "esAnalystView" ? row.original.shortlistedDomain?.remarks : row.original.remarks
          },
          socialLeads: row.original.socialDomain?.socialWorks?.[0]?.topLeads?.map(lead => {
            const leadData = lead.correctedLead || lead;
            return {
              id: leadData.id,
              domain: leadData.domain,
              domainSnapShotUrl: leadData.domainSnapShotUrl,
              copyright: leadData.copyright,
              description: leadData.description,
              isIrrelevant: leadData.irrelevant ?? false,
              missedStatus: leadData.missedStatus ?? 0,
              code: leadData.code,
              noSocialsAvailable: leadData.noSocialsAvailable ?? false,
              isInfluencer: leadData.isInfluencer ?? false,
              leadStatus: leadData.leadStatus ?? "active",
              wrongSocialStatus: leadData.wrongSocialStatus ?? 0,
              socials: lead.socials?.map(social => {
                const socialData = social.correctedSocial || social;
                return {
                  id: socialData.id,
                  platform: socialData.platform.toLowerCase(),
                  status: socialData.status,
                  followers: socialData.followers,
                  employees: socialData.employees,
                  url: socialData.url,
                  userFullName: socialData.userFullName,
                  isIrrelevant: socialData.irrelevant ?? false,
                  missedStatus: socialData.missedStatus ?? 0,
                  wrongSocialStatus: socialData.wrongSocialStatus ?? 0
                };
              }) || []
            };
          }) || [],
          socialWorkId: row.original.socialDomain?.socialWorks?.[0]?.id || null,
          reviewRemark: row.original.socialDomain?.socialWorks?.[0]?.reviewRemark,
          noLeadsAvailable: row.original.socialDomain?.socialWorks?.[0]?.noLeadsAvailable
        };

        return (
          <Tooltip title="Edit Leads & Socials">
            <IconButton
              size="small"
              onClick={() => {
                setSelectedRow(transformedData);
                setEditDialogOpen(true);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { 
      accessorKey: 'emailState',
      header: 'EStatus',
      size: 60,
      Cell: ({ row }) => {
        const socialLeads = row.original.socialDomain?.socialWorks?.[0]?.topLeads || [];
        const totalLeads = socialLeads.length;
        
        if (totalLeads === 0) return null;

        let leadsSubmitted = 0;
        let leadsInProgress = 0;
        let leadsNotStarted = 0;
        let unallocated = 0;

        socialLeads.forEach(lead => {
          const allocationStatus = lead.presentAllocation?.status;
          
          if (!lead.presentAllocation) {
            unallocated++;
          } else {
            switch(allocationStatus) {
              case 'submitted':
                leadsSubmitted++;
                break;
              case 'in progress':
                leadsInProgress++;
                break;
              case 'not started':
                leadsNotStarted++;
                break;
              default:
                leadsNotStarted++;
            }
          }
        });

        // Determine the state
        let state;
        if (unallocated === totalLeads) {
          state = 'unallocated';
        } else if (leadsSubmitted === totalLeads) {
          state = 'done';
        } else if (leadsSubmitted > 0 || leadsInProgress > 0) {
          state = 'processing';
        } else if (leadsNotStarted === totalLeads) {
          state = 'not_started';
        } else {
          state = 'pending';
        }

        const getTooltipText = () => {
          const parts = [];
          
          // Add user info for the first lead if available
          const firstLead = socialLeads[0];
          if (firstLead?.presentAllocation?.user) {
            const user = firstLead.presentAllocation.user;
            parts.push(`Processor: ${user.firstName} ${user.lastName}`);
          }

          // Add status counts
          if (unallocated > 0) parts.push(`${unallocated} unallocated`);
          if (leadsSubmitted > 0) parts.push(`${leadsSubmitted} submitted`);
          if (leadsInProgress > 0) parts.push(`${leadsInProgress} in progress`);
          if (leadsNotStarted > 0) parts.push(`${leadsNotStarted} not started`);
          
          return parts.join('\n');
        };

        return (
          <Tooltip 
            title={getTooltipText()} 
            componentsProps={{
              tooltip: {
                sx: {
                  whiteSpace: 'pre-line', // This ensures line breaks work in tooltip
                  bgcolor: 'background.paper',
                  color: 'text.primary',
                  border: '1px solid',
                  borderColor: 'divider',
                  p: 1,
                  '& .MuiTooltip-arrow': {
                    color: 'divider',
                  },
                },
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              {state === 'done' && (
                <CheckCircleIcon sx={{ color: 'success.main', fontSize: '1.1rem' }} />
              )}
              {state === 'processing' && (
                <EmailIcon sx={{ color: 'warning.main', fontSize: '1.1rem' }} />
              )}
              {state === 'not_started' && (
                <EmailIcon sx={{ color: 'info.main', fontSize: '1.1rem' }} />
              )}
              {state === 'pending' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem' }} />
              )}
              {state === 'unallocated' && (
                <EmailIcon sx={{ color: 'text.disabled', fontSize: '1.1rem', opacity: 0.5 }} />
              )}
            </Box>
          </Tooltip>
        );
      }
    }
  ], [activeView, mode]);

  useEffect(() => {
    console.log(rowSelection);
    console.log(table.getSelectedRowModel());
  }, [rowSelection]);

  const table = useMaterialReactTable({
    columns,
    data: filteredDomains,
    enableRowSelection: true,
    positionToolbarAlertBanner: 'bottom',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row?.id || '',
    initialState: {
      density: 'compact',
      columnVisibility: {
        leads: false,
        socials: false,
        remarks: false,
        actions: false,
      },
    },
    muiTableBodyProps: { sx: { backgroundColor: '#fcfcfa' } },
    muiTableBodyRowProps: { sx: { backgroundColor: '#fcfcfa', paddingY: 0 } },
    muiTablePaperProps: ({ table }) => ({
      sx: {
        zIndex: table.getState().isFullScreen ? 1250 : undefined,
      },
    }),
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    state: {
      pagination,
      rowSelection,
      isLoading: loading,
    },
    onPaginationChange: setPagination,
    
    enableColumnActions: false,
    enableColumnFilters: true,
    enableSorting: false,
    enableStickyHeader: true,


    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.75rem',
        padding: '4px',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        padding: '4px',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' width='100%' alignItems='center' spacing={2}>
        <FormControl size="small" sx={{ minWidth: 100 }}>
          <InputLabel id="domain-type-filter-label" sx={{ fontSize: '1rem' }}>Domain Type</InputLabel>
          <Select
            labelId="domain-type-filter-label"
            value={domainTypeFilter}
            label="Domain Type"
            onChange={(e) => setDomainTypeFilter(e.target.value)}
            sx={{
              height: '32px',
              '& .MuiSelect-select': {
                paddingTop: '4px',
                paddingBottom: '4px',
                fontSize: '0.75rem',
              },
            }}
          >
            <MenuItem value="All" sx={{ fontSize: '0.75rem' }}>All</MenuItem>
            <MenuItem value="Specific" sx={{ fontSize: '0.75rem' }}>Specific</MenuItem>
            <MenuItem value="Generic" sx={{ fontSize: '0.75rem' }}>Generic</MenuItem>
            <MenuItem value="Venture" sx={{ fontSize: '0.75rem' }}>Venture</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 80 }}>
          <InputLabel id="email-state-filter-label" sx={{ fontSize: '1rem' }}>E-Status</InputLabel>
          <Select
            labelId="email-state-filter-label"
            value={emailStateFilter}
            label="Email State"
            onChange={(e) => setEmailStateFilter(e.target.value)}
            sx={{
              height: '32px',
              '& .MuiSelect-select': {
                paddingTop: '4px',
                paddingBottom: '4px',
                fontSize: '0.75rem',
              },
            }}
          >
            <MenuItem value="All" sx={{ fontSize: '0.75rem' }}>All States</MenuItem>
            <MenuItem value="unallocated" sx={{ fontSize: '0.75rem', color: 'text.disabled', opacity: 0.5 }}>Unallocated</MenuItem>
            <MenuItem value="not_started" sx={{ fontSize: '0.75rem', color: 'info.main' }}>Not Started</MenuItem>
            <MenuItem value="processing" sx={{ fontSize: '0.75rem', color: 'warning.main' }}>Processing</MenuItem>
            <MenuItem value="done" sx={{ fontSize: '0.75rem', color: 'success.main' }}>Done</MenuItem>
            {/* <MenuItem value="no_leads" sx={{ fontSize: '0.75rem' }}>No Leads</MenuItem> */}
          </Select>
        </FormControl>

        {(activeView === 'adminView' || activeView === 'managerialView') && (
          <Tooltip title="Update from Sheet" placement="top">
            <Button
              variant="outlined"
              size='small'
              startIcon={<GridOnRoundedIcon />}
              sx={{ 
                textTransform: 'none',
                height: '32px',
                fontSize: '0.75rem'
              }}
              onClick={() => setUpdateDialogOpen(true)}
            >
              Update
            </Button>
          </Tooltip>
        )}
        <Tooltip title="Domains with Leads Only" placement="top">
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showDomainWithLeadsOnly}
                onChange={(event) => setShowDomainWithLeadsOnly(event.target.checked)}
              />
            }
            label={
              <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                Leads Only
              </Typography>
            }
            sx={{ ml: 1 }}
          />
        </Tooltip>
        <Box flexGrow={1} />
        {Object.keys(rowSelection).length > 0 && (
          <Stack direction="row" spacing={1}>
            {(activeView === 'adminView' || activeView === 'managerialView') && (
              <Button
                variant="outlined"
                size='small'
                disabled={fetchingStats}
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  setFetchingStats(true);
                  fetchStats(rowSelection).then(res => {
                    setFetchingStats(false);
                    fetchDomains(mode);
                    setSnackbar({ open: true, message: 'Estibot Stats Fetched Successfully! GoDaddy Stats Fetching in background', severity: 'success', autoHideDuration: 5000 });
                  }).catch(err => {
                    console.log(err);
                    setFetchingStats(false);
                    setSnackbar({ open: true, message: 'Error Fetching Estibot Stats!', severity: 'error', autoHideDuration: 2000 });
                  });
                }}
              >
                {fetchingStats ? "Fetching..." : "Fetch Stats"}
              </Button>
            )}
            <Button
              variant="outlined"
              size='small'
              sx={{ textTransform: 'none' }}
              onClick={handleMoveToReco}
            >
              Move to Reco
            </Button>
            {researchTabValue === 'DoneResearch' && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpenEmailAllocateDialog(true)}
                sx={{ textTransform: 'none' }}
              >
                Allocate to Email Hub
              </Button>
            )}
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="action-select-label" sx={{ fontSize: '0.8rem' }}>Research</InputLabel>
              <Select
                labelId="action-select-label"
                label="Research"
                value=""
                onChange={(e) => {
                  if (e.target.value === 'allocate') {
                    setOpenAllocateDialog(true);
                  } else if (e.target.value === 'claim') {
                    // Move to Claim Hub
                    const selectedRows = table.getSelectedRowModel().rows;
                    const selectedIds = selectedRows.map(row => row.original.id);
                    const moveFunction = activeView === "esAnalystView" 
                      ? moveToResearchEsAnalyst 
                      : moveToReasearch;

                    moveFunction(selectedIds)
                      .then(() => {
                        setSnackbar({
                          open: true,
                          message: 'Successfully moved domains to research hub',
                          severity: 'success',
                          autoHideDuration: 2000,
                        });
                        fetchDomains(mode);
                        setRowSelection({});
                      })
                      .catch(err => {
                        console.error(err);
                        setSnackbar({
                          open: true,
                          message: 'Failed to move domains to research hub',
                          severity: 'error',
                          autoHideDuration: 2000,
                        });
                      });
                  }
                }}
                sx={{
                  height: '32px',
                  '& .MuiSelect-select': {
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    fontSize: '1rem'
                  }
                }}
              >
                <MenuItem value="allocate" sx={{ fontSize: '0.8rem' }}>Allocate To</MenuItem>
                <MenuItem value="claim" sx={{ fontSize: '0.8rem' }}>Move to Claim Hub</MenuItem>
              </Select>
            </FormControl>
{/* 
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="action-select-label" sx={{ fontSize: '0.8rem' }}>Email Hub</InputLabel>
              <Select
                labelId="action-select-label"
                label="Email Hub"
                value=""
                onChange={(e) => {
                  if (e.target.value === 'allocate') {
                    setOpenEmailAllocateDialog(true);
                  } else if (e.target.value === 'claim') {
                    // Move to Claim Hub
                    const selectedRows = table.getSelectedRowModel().rows;
                    const selectedIds = selectedRows.map(row => row.original.id);
                    const moveFunction = moveToEmailCenter;

                    moveFunction(selectedIds)
                      .then(() => {
                        setSnackbar({
                          open: true,
                          message: 'Successfully moved domains to Email hub',
                          severity: 'success',
                          autoHideDuration: 2000,
                        });
                        fetchDomains(mode);
                        setRowSelection({});
                      })
                      .catch(err => {
                        console.error(err);
                        setSnackbar({
                          open: true,
                          message: 'Failed to move domains to Email hub',
                          severity: 'error',
                          autoHideDuration: 2000,
                        });
                      });
                  }
                }}
                sx={{
                  height: '32px',
                  '& .MuiSelect-select': {
                    paddingTop: '1px',
                    paddingBottom: '1px',
                    fontSize: '1rem'
                  }
                }}
              >
                <MenuItem value="allocate" sx={{ fontSize: '0.8rem' }}>Allocate To</MenuItem>
                <MenuItem value="claim" sx={{ fontSize: '0.8rem' }}>Move to Email Hub</MenuItem>
              </Select>
            </FormControl> */}
          </Stack>
        )}
      </Stack>
    ),
  });

  const handleResearchTabChange = (event, newValue) => {
    setResearchTabValue(newValue);
  };

  const renderTabLabel = (tabValue) => {
    const tabLabels = {
      'All': 'All',
      'PendingResearch': 'Pending Research',
      'DoneResearch': 'Done Research'
    };
    return tabLabels[tabValue] || tabValue;
  };

  const handleMoveToReco = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => {
      if (activeView === "esAnalystView") {
        return row.original.id;
      } else {
        return row.original.id;
      }
    });

    const moveFunction = activeView === "esAnalystView" 
      ? moveToRecoEsAnalyst 
      : moveToReco;

    moveFunction(selectedIds)
      .then(() => {
        setSnackbar({ 
          open: true, 
          message: 'Successfully moved domains to recommendations', 
          severity: 'success', 
          autoHideDuration: 2000 
        });
        fetchDomains(mode);
        setRowSelection({});
      })
      .catch(err => {
        console.error(err);
        setSnackbar({ 
          open: true, 
          message: 'Failed to move domains to recommendations', 
          severity: 'error', 
          autoHideDuration: 2000 
        });
      });
  };

  const handleAllocateToUser = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => row.original.id);

    const payload = {
      domainIds: selectedIds,
      user: selectedUser,
    };

    setAllocating(true);
    allocateToSocialUser(payload).then(() => {
      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to social user',
        severity: 'success',
        autoHideDuration: 2000,
      });
      setOpenAllocateDialog(false);
      setSelectedUser('');
      setAllocating(false);
    }).catch(err => {
      console.error(err);
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to social user',
        severity: 'error',
        autoHideDuration: 2000,
      });
      setAllocating(false);
    });
  };

  const handleAllocateToEmailUser = () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const selectedIds = selectedRows.map(row => row.original.id);

    const payload = {
      domainIds: selectedIds,
      user: selectedUser,
    };

    setAllocating(true);
    allocateToEmailProcessor(payload).then(() => {
      setSnackbar({
        open: true,
        message: 'Successfully allocated domains to email user',
        severity: 'success',
        autoHideDuration: 2000,
      });
      setOpenEmailAllocateDialog(false);
      setSelectedUser('');
      setAllocating(false);
    }).catch(err => {
      console.error(err);
      setSnackbar({
        open: true,
        message: 'Failed to allocate domains to email user',
        severity: 'error',
        autoHideDuration: 2000,
      });
      setAllocating(false);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={snackbar.open} autoHideDuration={snackbar.autoHideDuration} onClose={() => setSnackbar({ open: false, message: '', severity: '' }) }>
          <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
        </Snackbar>
        <Stack direction='row' spacing={2}>
          <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Shortlist
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          {(isAdminUser || isEDManagerUser) && (
            <>
              {mode !== 'es' && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setOpenDialog(true)}
                  sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
                >
                  Add Domains
                </Button>
              )}

              </>
              )}
              {(isAdminUser || (isEDManagerUser && isESManagerUser)) && (
                <>
                  <Button
                    variant="outlined"
                onClick={() => {
                  const newMode = mode === 'ed' ? 'es' : 'ed';
                  setMode(newMode);
                  setDomains([]);
                  fetchDomains(newMode);
                }}
                sx={{ height: 40, borderRadius: 100, textTransform: 'none' }}
              >
                Switch To {mode === 'ed' ? 'ES' : 'ED'}
              </Button>
            </>
          )}
          {/* <UploadCSVDialog title='Domains' open={openDialog} handleClose={handleCloseDialog} setState={setNewDomains} /> */}
          <UploadCSVDialogShortlistedDomain
            open={openDialog}
            handleClose={handleCloseDialog}
            setState={setNewDomains}
          />
        </Stack>

        <Snackbar
          open={uploadSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>

        <Snackbar
          open={uploadFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setUploadFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Upload Failed!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateSuccess}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateSuccess(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Allocated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={allocateFail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setAllocateFail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Allocation Failed!
          </Alert>
        </Snackbar>

        <TabContext value={researchTabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList 
              onChange={handleResearchTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ minHeight: 36 }}
            >
              {['All', 'PendingResearch', 'DoneResearch'].map((state) => (
                <Tab 
                  key={state} 
                  label={renderTabLabel(state)}
                  value={state}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    marginRight: 0.5,
                    borderRadius: '4px 4px 0 0',
                    minHeight: 36,
                    padding: '6px 12px',
                    fontSize: '0.75rem',
                    '&.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel value={researchTabValue} sx={{ padding: 1 }}>
            <MaterialReactTable table={table} />
          </TabPanel>
        </TabContext>
        <Dialog fullWidth open={openAllocateDialog} onClose={() => setOpenAllocateDialog(false)}>
          <DialogTitle>Allocate to Social User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{mt:1}}>
              <InputLabel id="user-select-label">Select User</InputLabel>
              <Select
                labelId="user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {children.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAllocateDialog(false)}>Cancel</Button>
            <Button onClick={handleAllocateToUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog fullWidth open={openEmailAllocateDialog} onClose={() => setOpenEmailAllocateDialog(false)}>
          <DialogTitle>Allocate to Email User</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" sx={{mt:1}}>
              <InputLabel id="email-user-select-label">Select User</InputLabel>
              <Select
                labelId="email-user-select-label"
                label="Select User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                {emailAgent.map((child) => (
                  <MenuItem key={child.email} value={child.email}>
                    {child.firstName + " " + child.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEmailAllocateDialog(false)}>Cancel</Button>
            <Button onClick={handleAllocateToEmailUser} variant="contained" disabled={allocating}>
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
        <EditLeadsDialog
          open={editDialogOpen}
          onClose={() => {
            setEditDialogOpen(false);
            setSelectedRow(null);
          }}
          data={selectedRow}
          onSuccess={() => {
            fetchDomains(mode);
          }}
        />
        <UpdateShortlistedDomain 
          open={updateDialogOpen}
          handleClose={() => {
            setUpdateDialogOpen(false);
          }}
          onSuccess={() => {
            fetchDomains(mode);
          }}
        />
      </Stack>
    </ThemeProvider>
  );
}
